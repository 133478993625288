import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/cloud/Picture1.png'
import Picture2 from '../assets/cloud/Picture2.png'
import Picture3 from '../assets/cloud/Picture3.png'
import Picture4 from '../assets/cloud/Picture4.png'
import Picture5 from '../assets/cloud/Picture5.png'
import Picture6 from '../assets/cloud/Picture6.png'
import Picture7 from '../assets/cloud/Picture7.png'
import Picture8 from '../assets/cloud/Picture8.png'
import Picture9 from '../assets/cloud/Picture9.png'
import Picture10 from '../assets/cloud/Picture10.png'
import Picture11 from '../assets/cloud/Picture11.png'
import Picture12 from '../assets/cloud/Picture12.png'
import Picture13 from '../assets/cloud/Picture13.png'
import Picture14 from '../assets/cloud/Picture14.png'
import Picture15 from '../assets/cloud/Picture15.png'
import Picture16 from '../assets/cloud/Picture16.png'



const InsideResearchCloud = () => {
    const tags = ["Cloud", "AWS", "Containerization", "Kubernetes"]
        const computeResources = [
            {
              type: "Bare Metal",
              platform: "Physical, dedicated servers",
              scaling: "Manual scaling",
              applications: "High-performance AI training, HPC applications",
              features: "Maximum performance, full hardware control",
              examples: "IBM Cloud Bare Metal, Oracle Bare Metal, Packet"
            },
            {
              type: "Virtual Machines (VMs)",
              platform: "Hypervisor-based VMs",
              scaling: "Flexible with auto-scaling",
              applications: "Flexible AI workloads, multi-tenancy",
              features: "Cost-effective with shared hardware, isolated environment",
              examples: "Amazon EC2, Google Compute Engine, Azure VMs"
            },
            {
              type: "Containers",
              platform: "Docker/Kubernetes clusters",
              scaling: "Rapid horizontal scaling",
              applications: "Scalable AI inference, microservices",
              features: "Lightweight, portable, and consistent across environments",
              examples: "Amazon EKS, Azure AKS, Google GKE"
            },
            {
              type: "Serverless",
              platform: "Cloud provider functions",
              scaling: "Automatic scaling",
              applications: "Event-driven AI tasks, burstable workloads",
              features: "Cost-effective, pay-as-you-go, minimal management",
              examples: "AWS Lambda, Google Cloud Functions, Azure Functions"
            }
          ];
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        Cloud Infrastructure for AI Workloads
                    </h2>
                    
                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> As CSPs offer diverse options for AI compute, 
                        this report guides companies on selecting optimal cloud services to avoid 
                        suboptimal decisions in an increasingly competitive landscape.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 11/4/2024
                    </div>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Abstract</h2>

                        <div className="grid lg:grid-cols-2 gap-8">
                            {/* Text Content */}
                            <div>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Access to services from Cloud Service Providers 
                                    (CSPs) can relieve you of the need to maintain 
                                    your own data centers. As competition among 
                                    CSPs intensifies, companies—whether startups or 
                                    large corporations new to AI—can opt for an 
                                    asset-light approach by relying on cloud services. 
                                    Beyond the sheer number of providers now 
                                    offering cloud compute, there is also a wide 
                                    variety of services available, which introduces a 
                                    paradox of choice and can often lead to 
                                    management making suboptimal decisions for 
                                    their AI compute needs. This report will cover the 
                                    various options available when using a CSP for AI 
                                    compute workloads, diving deep into each type, 
                                    its pros and cons, and the specific services you 
                                    might consider.
                                </p>
                            </div>

                            {/* Images Grid */}
                            <div className="grid grid-cols-2 gap-1">
                                <img
                                    src={Picture1}
                                    alt="Cloud Infrastructure 1"
                                    className="w-1/2 rounded-lg"
                                />
                                <img
                                    src={Picture2}
                                    alt="Cloud Infrastructure 2"
                                    className="w-1/2 rounded-lg"
                                />
                                <img
                                    src={Picture4}
                                    alt="Cloud Infrastructure 3"
                                    className="w-1/2 rounded-lg"
                                />
                                <img
                                    src={Picture3}
                                    alt="Cloud Infrastructure 4"
                                    className="w-1/2 rounded-lg"
                                />
                                <img
                                    src={Picture5}
                                    alt="Cloud Infrastructure 5"
                                    className="w-1/2 rounded-lg"
                                />
                                <img
                                    src={Picture6}
                                    alt="Cloud Infrastructure 6"
                                    className="w-1/2 rounded-lg"
                                />
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Compute Resource Allocation Options</h2>
                        <h3 className="2xl:text-3xl mb-6">Types of Compute Resource Setups</h3>

                        <div className="flex flex-col gap-8">
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                When choosing infrastructure for AI workloads, one of the first considerations is how resources are allocated. 
                                Here are the main types of compute resource setups:
                            </p>

                            <figure className="w-full">
                                <img
                                    src={Picture7}
                                    alt="Compute Resource Setups"
                                    className="w-full bg-gray-300 p-4 rounded-lg"
                                />
                            </figure>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Types of Compute Resource Setups</h2>
                        <h3 className="2xl:text-3xl mb-6">Bare Metal Servers</h3>

                        <div className="grid lg:grid-cols-3 gap-8">
                            <div>
                                <img
                                    src={Picture8}
                                    alt="Bare Metal Servers"
                                    className="w-full rounded-lg"
                                />
                            </div>
                            <div className="lg:col-span-2 ">
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Bare metal servers are physical servers dedicated to a single tenant without 
                                    any virtualization layer. They provide direct access to hardware resources.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    In a bare metal setup, applications like training, inference, and databases run 
                                    directly on dedicated hardware without virtualization, providing high- 
                                    performance, direct access to resources. This setup is ideal for intensive AI 
                                    tasks, such as large scale LLM training, requiring maximum processing power, 
                                    customization, and low latency.
                                </p>
                            </div>

                        </div>

                        {/* Additional content below the first grid */}
                        <div className="grid lg:grid-cols-2 gap-8 mt-12">
                            {/* Image */}
                            <div>
                                <img
                                    src={Picture12}
                                    alt="Cloud Infrastructure Options"
                                    className="w-full rounded-lg"
                                />
                            </div>

                            {/* Options List */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Options you could consider:</h4>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-4">
                                    <li>
                                        • Oracle Cloud Infrastructure (OCI): Offers bare metal 
                                        instances with NVIDIA GPUs (H100, L40S, A100) tailored 
                                        for AI and machine learning, with plans to add H200 and 
                                        Blackwell GPUs, as well as AMD Instinct MI300X GPUs.
                                    </li>
                                    <li>
                                        • NVIDIA GH200 Superchip: Oracle provides the NVIDIA 
                                        GH200 Grace Hopper Superchip for efficient large 
                                        language model (LLM) inference.
                                    </li>
                                    <li>
                                        • IBM Cloud: Provides customizable bare metal servers with 
                                        NVIDIA GPUs and Intel/AMD CPUs, allowing specific GPU 
                                        selection and integration with IBM Watson for enhanced 
                                        AI capabilities.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Types of Compute Resource Setups</h2>
                        <h3 className="2xl:text-3xl mb-6">Virtual Machine</h3>

                        {/* First grid - Main content */}
                        <div className="grid lg:grid-cols-3 gap-8">
                            <div>
                                <img
                                    src={Picture9}
                                    alt="Virtual Machine Setup"
                                    className="w-full rounded-lg"
                                />
                            </div>
                            <div className="lg:col-span-2">
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Virtualized servers run on a hypervisor, allowing multiple virtual machines 
                                    (VMs) to share the same physical hardware.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    A hypervisor is software that creates and manages virtual machines by allowing 
                                    multiple operating systems to share a single physical host's hardware resources. 
                                    This setup provides a balance between performance, flexibility, and cost- 
                                    effectiveness, making it suitable for a wide range of AI workloads. Virtualized 
                                    environments offer isolation between applications while enabling flexible 
                                    resource allocation, ideal for tasks like inference or moderate-scale model 
                                    training.
                                </p>
                            </div>
                            
                        </div>

                        {/* Additional content below the first grid */}
                        <div className="grid lg:grid-cols-2 gap-8 mt-12">
                            {/* Image */}
                            <div>
                                <img
                                    src={Picture13}
                                    alt="Virtual Machine Options"
                                    className="w-full rounded-lg"
                                />
                            </div>

                            {/* Options List */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Options you could consider:</h4>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-4">
                                    <li>
                                        • Amazon EC2: Provides a variety of GPU-optimized VM types, 
                                        suitable for both training and inference AI tasks.
                                    </li>
                                    <li>
                                        • Microsoft Azure Virtual Machines: Supports GPU-accelerated 
                                        VMs for high-performance AI applications, offering both Linux 
                                        and Windows environments.
                                    </li>
                                    <li>
                                        • Google Compute Engine: Offers customizable VMs with GPU 
                                        support, ideal for scaling AI inference and moderate model 
                                        training.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Types of Compute Resource Setups</h2>
                        <h3 className="2xl:text-3xl mb-6">Containers</h3>

                        {/* First grid - Main content */}
                        <div className="grid lg:grid-cols-3 gap-8">
                            {/* Image - Takes 1/3 width on large screens */}
                            <div>
                                <img
                                    src={Picture10}
                                    alt="Container Setup"
                                    className="w-full rounded-lg"
                                />
                            </div>
                            {/* Text Content - Takes 2/3 width on large screens */}
                            <div className="lg:col-span-2">
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Containers bundle applications and everything they need to run in small, 
                                    separate units. This setup makes it easy to deploy and scale AI models quickly 
                                    and reliably across different environments. Containers work especially well for 
                                    systems with multiple small tasks (microservices) and for running AI tasks, as 
                                    they use resources efficiently and can scale up or down quickly.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Containers run applications (like training and inference jobs) in isolated units on 
                                    shared hardware. A Container Engine manages these containers on top of 
                                    the Host Operating System, enabling efficient and consistent operation across 
                                    multiple environments.
                                </p>
                            </div>

                            
                        </div>

                        {/* Additional content below the first grid */}
                        <div className="grid lg:grid-cols-2 gap-8 mt-12">
                            {/* Image */}
                            <div>
                                <img
                                    src={Picture14}
                                    alt="Container Options"
                                    className="w-full rounded-lg"
                                />
                            </div>

                            {/* Options List */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Options you could consider:</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Managed Kubernetes services like Amazon EKS (Elastic 
                                    Kubernetes Service), Azure Kubernetes Service, and Google 
                                    Kubernetes Engine offer scalable container orchestration with 
                                    AI integration, supporting GPU-based inference and machine 
                                    learning model deployment.
                                </p>

                                <h4 className="2xl:text-3xl font-semibold mb-4">Next Slide:</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Some services provide ML-specific, container-based offerings, 
                                    tailored for the unique demands of AI workloads.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Types of Compute Resource Setups</h2>
                        <h3 className="2xl:text-3xl mb-6">Managed AI Platforms for AI Workloads (Containers – 2)</h3>

                        {/* Main content */}
                        <div className="flex flex-col gap-8">
                            {/* Text content */}
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                When selecting infrastructure for AI workloads, it's crucial to consider how resources are allocated. Managed AI platforms 
                                offer end-to-end environments for building, training, and deploying machine learning models, leveraging containers 
                                behind the scenes to provide a fully managed experience. This approach allows organizations to focus on AI development 
                                without the burden of managing underlying infrastructure.
                            </p>

                            {/* Images container */}
                            <div className="grid lg:grid-cols-2 gap-8">
                                <img
                                    src={Picture15}
                                    alt="Managed AI Platform Visualization 1"
                                    className="w-full rounded-lg"
                                />
                                <img
                                    src={Picture16}
                                    alt="Managed AI Platform Visualization 2"
                                    className="w-full rounded-lg"
                                />
                            </div>
                        </div>

                        {/* Options content */}
                        <div className="mt-12">
                            <h4 className="2xl:text-3xl font-semibold mb-6">Options you could consider:</h4>
                            
                            <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-6">
                                <li>
                                    • AWS SageMaker: Provides tools for building, training, tuning, and deploying machine learning models. It includes 
                                    SageMaker Studio, SageMaker Autopilot for AutoML, and support for custom containers.
                                </li>
                                
                                <li>
                                    • Google Vertex AI: Offers a unified platform for AI development with tools for data labeling, model training, and 
                                    deployment. Vertex AI integrates with Google's Tensor Processing Units (TPUs) for accelerated training.
                                </li>
                                
                                <li>
                                    • Azure Machine Learning: Provides an integrated workspace for managing machine learning workflows, with tools for 
                                    automated ML, responsible AI, and deployment to various environments, including Kubernetes.
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Types of Compute Resource Setups</h2>
                        <h3 className="2xl:text-3xl mb-6">Serverless Compute</h3>

                        {/* Main description */}
                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Serverless computing offers an <strong>event-driven, on-demand approach</strong> where compute resources are allocated 
                            automatically in response to workload needs. This setup allows developers to run code <strong>without managing servers</strong>, 
                            making it ideal for lightweight, burstable AI tasks such as <strong>inference, data preprocessing, or real-time processing</strong>.
                        </p>

                        {/* Options section */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-6">Options you could consider:</h4>
                            <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-4">
                                <li>
                                    • Amazon SageMaker Serverless Inference: Allows serverless deployment and scaling of ML models, with automatic compute resource 
                                    management.
                                </li>
                                <li>
                                    • Azure Functions: Microsoft's serverless service supports deploying AI models for inference, integrated within Azure's AI offerings.
                                </li>
                                <li>
                                    • Hugging Face NVIDIA NIM API: Offers serverless inference for enterprise users on Hugging Face, using NVIDIA DGX Cloud for accelerated 
                                    compute.
                                </li>
                                <li>
                                    • OpenAI API: Provides serverless access to language models and AI capabilities through an easy-to-use API.
                                </li>
                            </ul>
                        </div>

                        {/* Image */}
                        <div className="mb-8">
                            <img
                                src={Picture11}
                                alt="Serverless Computing Workflow"
                                className="w-full bg-gray-300 p-4 rounded-lg"
                            />
                        </div>

                        {/* Workflow section */}
                        <div>
                            <h4 className="2xl:text-3xl font-semibold mb-6">Sample Serverless Workflow:</h4>
                            <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-4">
                                <li>• Client makes an HTTP request</li>
                                <li>• API Gateway triggers the serverless function and function makes an API call to the AI service</li>
                                <li>• AI service sends input to the model</li>
                                <li>• Model returns its prediction</li>
                                <li>• Results flow back as responses</li>
                            </ul>
                        </div>
                    </section>
                    
                    <section style={{ marginBottom: '40px' }}>
                        <div className="overflow-x-auto">
                            <table className="min-w-full">
                            <thead>
                                <tr>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Compute Setup
                                </th>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Compute Platform
                                </th>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Scaling
                                </th>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Applications
                                </th>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Unique Features
                                </th>
                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-semibold border border-blue-800">
                                    Examples of Services
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {computeResources.map((resource, index) => (
                                <tr key={index}>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    <span className="font-bold">{resource.type}</span>
                                    </td>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    {resource.platform}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    {resource.scaling}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    {resource.applications}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    {resource.features}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">
                                    {resource.examples}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                    </section>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchCloud;
