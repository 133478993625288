import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="mt-10 px-10 text-white">
        <h1 className="text-3xl font-bold text-white mb-4">Privacy Policy</h1>
        <p className="text-sm text-gray-100 mb-8">Last Updated February 24, 2025</p>

        <div className="prose max-w-none">
          <p className="text-gray-200 mb-6">
            FPX, Inc. ("FPX") is committed to safeguarding the "Personal Information" entrusted to us by our clients and visitors to our Website. FPX has implemented this Privacy Policy to govern the use of Personal Information that we collect through the Website, including Personal Information you send to us. This Privacy Policy does not govern Personal Information collected from any other source or in any other manner. This Privacy Policy is intended to provide you with information as to:
          </p>
          <ul className="list-none text-gray-200 mb-6 ml-4">
            <li className="mb-2">(i) The type of information that is collected</li>
            <li className="mb-2">(ii) How the collected information is used and with whom it is shared</li>
            <li className="mb-2">(iii) How we intend to protect the information</li>
            <li className="mb-2">(iv) How you can access such information and have it modified and/or removed</li>
          </ul>

          <p className="text-gray-200 mb-6">
            This Privacy Policy may be changed from time to time, and we reserve the right to make changes without prior 
            individual notice. Such changes are effective upon publication of the revised Privacy Policy. Please review this 
            Privacy Policy often so that you will remain up to date with respect to FPX's current policies, including its 
            policies concerning the gathering and use of information that may be collected on our Website. You are responsible 
            for making sure you are aware of the provisions of FPX's most current Privacy Policy. You can tell when the Privacy 
            Policy was last modified by checking the "last updated" date at the top of the policy.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Collection and Use of Information</h2>
          <h3 className="text-xl font-semibold text-gray-200 mb-3">Personal Information</h3>
          <p className="text-gray-200 mb-6">
            "Personal Information" is information that uniquely identifies you, such as your name, address, email address, and telephone number. When you register with FPX and subscribe to one of our services, FPX will collect your Personal Information. For example, we collect your Personal Information if you subscribe to one of our research packages. When you subscribe to our services, your subscription and account information is password-protected. Only you have access to password-protected areas of the Website—do not share your password with anyone. Additionally, when you enter any password-protected area of the Website, the Website will recognize who you are and will collect information that you submit. Any information collected about you from the Website may be associated with other information that FPX has collected about you. We may ask for additional information, subject to laws and regulations governing the financial industry, which may require such information for Know-Your-Customer and Anti-Money Laundering purposes.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">IP Address</h3>
          <p className="text-gray-200 mb-6">
            An IP (Internet Protocol) address is a number that is automatically assigned to your computer by your internet service provider when you log on. Your IP address is not linked to your Personal Information, but FPX does reserve the right to use IP addresses to identify individuals who threaten the Website or other users or visitors. IP addresses may also be used to help diagnose problems with the Site and to gather broad demographic information. The information we collect in this manner is usually anonymized, and we will not identify you from this data, subject to a need to do so to protect the Site and/or other users.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Other Information</h3>
          <p className="text-gray-200 mb-6">
            When you access the Website, FPX will automatically receive some information about your use of the service. The type of information that is automatically collected by FPX includes such things as the date and time of access of the Website, the IP address of the computer that you are using, the domain and host from which you access the Internet, your browser software and operating system, the referring website address, and the specific activities you are engaged in while visiting the Site. This aggregate tracking information is used to help FPX improve and enhance the website experience for all of its visitors. The information we collect in this manner is usually anonymized, and we will not identify you from this data, subject to a need to do so to protect the Site and/or other users.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Your Consent</h3>
          <p className="text-gray-200 mb-6">
            We only collect, use, and/or disclose your Personal Information with your consent. We will request your consent to use your Personal Information for a particular purpose when we collect such information. Additionally, when you submit your Personal Information to us to become a subscriber and/or registered user of our services, you are consenting to the collection of your Personal Information for the purposes identified or apparent at the time of collection and/or as otherwise described in this Privacy Policy.
          </p>

          <p className="text-gray-200 mb-6">
            You must be 18 years of age and a resident of the United States to use this Site. FPX does not knowingly collect or maintain any Personal Information from children under the age of 13, and no part of the Website is designed with the purpose of attracting such children.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Use of Your Personal Information</h3>
          <p className="text-gray-200 mb-6">
            We use your Personal Information with your consent for the purposes for which it is submitted, such as to provide our research services and other services and products offered by FPX through the Website; to reply to your requests for information; and to respond to other requests you have. Your Personal Information may also be used in the following ways:
          </p>
          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">to deliver materials, information, and services to you;</li>
            <li className="mb-2">to give you notice of and/or resolve any problems that may arise in connection with our services;</li>
            <li className="mb-2">to better understand the needs and interests of our customers;</li>
            <li className="mb-2">to analyze the success of our products, services, and Website;</li>
            <li className="mb-2">to manage the Website's interactive functionality;</li>
            <li className="mb-2">to help FPX improve and enhance the Website experience for all of its visitors, and customize the content on our Site;</li>
            <li className="mb-2">to provide information about potential client/consultant projects;</li>
            <li className="mb-2">for compliance with FPX's legal and regulatory obligations;</li>
            <li className="mb-2">as otherwise reasonably required to provide customer services and maintain the Site;</li>
            <li className="mb-2">to investigate potentially unlawful activity;</li>
            <li className="mb-2">for any other purpose outlined in this Privacy Policy;</li>
            <li className="mb-2">with your consent, to communicate with you about FPX products or services we think may be of interest to you; and as permitted by law.</li>
            <li className="mb-2">We may also use your Personal Information to contact you via direct postal mail and/or electronically, with your consent, regarding your relationship with FPX.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Opting in and opting out of electronic communications</h3>
          <p className="text-gray-200 mb-6">
            You may opt out of receiving future electronic communications, such as emails, from FPX at any time by:
          </p>
          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">Sending an email directly to info@fp8.io;</li>
            <li className="mb-2">Writing to FPX's corporate offices (if applicable); or</li>
            <li className="mb-2">Clicking on the "opt-out" or "unsubscribe" link in any electronic communication you receive.</li>
          </ul>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Do Not Track Signals</h3>
          <p className="text-gray-200 mb-6">
            Certain web browsers provide users with an option to send a "Do Not Track" signal to the website that you are visiting, advising the recipient website that you do not want to have your online activity tracked. However, the way browsers communicate these "Do Not Track" signals is not yet uniform, and accordingly, our Website does not take any action in response to such signals. In the event a final standard is developed and accepted, we may reassess how we should respond to such signals.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Use of Your Personal Information by FPX</h3>
          <p className="text-gray-200 mb-6">
            FPX is a standalone C corporation and does not share your Personal Information with any parent, subsidiary, or affiliate entities (as FPX does not have a holding/subsidiary structure) without your consent. We also will not share your Personal Information with unaffiliated third parties, except as stated in this Privacy Policy or with your consent. Your Personal Information will be stored on servers located in the United States.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Use of Your Personal Information by FPX</h3>
          <p className="text-gray-200 mb-6">
            FPX is a standalone C corporation and does not share your Personal Information with any parent, subsidiary, or affiliate entities (as FPX does not have a holding/subsidiary structure) without your consent. We also will not share your Personal Information with unaffiliated third parties, except as stated in this Privacy Policy or with your consent. Your Personal Information will be stored on servers located in the United States.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Use of Your Personal Information by Our Service Providers</h3>
          <p className="text-gray-200 mb-6">
            From time to time, we may hire third parties to assist us with certain functions and services, such as data processing, website maintenance and development, transaction processing, promotional and marketing services, customer service, compliance, legal or accounting services, and/or business or sales analysis. FPX may share your Personal Information, without restriction (and regardless of whether you have chosen to limit our sharing of your Personal Information as collected on the Website), with such companies that we hire to perform services for us. In such situations, FPX requires that these companies agree to maintain the confidentiality of your Personal Information and to comply with the safeguards described in this Privacy Policy. These companies have access to the Personal Information needed to perform their functions but are not permitted to share or use such information for any other purposes.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Lawful Access, Legal Action and Investigations</h3>
          <p className="text-gray-200 mb-6">
            Your Personal Information may be disclosed to nonaffiliated third parties as either required by, or to comply with, applicable laws and regulations. We may disclose Personal Information if we believe it is necessary to investigate, prevent, or take action regarding suspected illegal activities or fraud, situations involving the physical safety of any person, violations of our Privacy Policy, or otherwise in accordance with applicable law. For example, FPX may disclose your Personal Information to comply with investigations by regulatory authorities or enforcement agencies in the United States of America or elsewhere, or to protect against or prevent actual or potential fraud. Furthermore, FPX may disclose your Personal Information if it has a reasonable good faith basis to believe that it is required to do so by law, compelled by court order or legal process, or is reasonably necessary and/or appropriate to: (i) protect the rights, property, and/or safety of FPX or any other person or entity; or (ii) prevent or take action concerning any alleged: (a) illegal activity or (b) breach of this Privacy Policy.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Use of Cookies, Google Analytics</h3>
          <p className="text-gray-200 mb-6">
            The Site may make use of "cookies" – small data files sent to your computer based on the information collected about your visit. They contain information allowing us to recognize when you are visiting the Site and to keep track of your preferences. Through the use of a cookie, we may automatically collect information about your online activity on our Site, such as the web pages you visit, the links you click, and the searches you conduct. Cookies are stored in your computer's browser or on your hard disk and can then be retrieved by the website.
          </p>

          <p className="text-gray-200 mb-6">
            If you want to delete any cookies that were already sent to your computer or personal device, please refer to your web browser's "Help" facility. In addition, most web browsers can be set to prevent you from receiving new cookies, notify you before accepting cookies, or disable cookies altogether. Details about this can normally be found in the "Help" facility provided with your browser. Please note that if you disable cookies, you may not be able to take advantage of some of the Website's interactive functionality.
          </p>

          <p className="text-gray-200 mb-6">
            The Website may also use Google Analytics, a web analytics tool that helps website owners understand how visitors engage with their websites. We can view a variety of reports about how visitors interact with our Site so we can improve it. Google Analytics collects information anonymously, uses cookies and IP addresses, and reports website trends without identifying individual visitors. For more information related to Google Analytics, click here: https://www.google.com/analytics/learn/privacy.html. You can opt out of Google Analytics by installing the Google Analytics Opt-out Browser Add-On. For more information about and to install the Google Analytics Opt-out Browser Add-on, click here: https://tools.google.com/dlpage/gaoptout?hl=en.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Other Third-Party Services</h3>
          <p className="text-gray-200 mb-6">
            The Website may be enhanced by other third-party services to understand our users in order to help us provide a better browsing experience.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Disclosure of Personal Information to Non-Affiliated Third Parties</h3>
          <p className="text-gray-200 mb-6">
            We will not sell, rent, or market Personal Information collected on this Site to non-affiliated third parties for their marketing purposes without your consent. However, we may disclose aggregated statistics about visitors to the Site, along with anonymous information concerning individual users of the Site. We may combine anonymous or aggregate information we collect with additional anonymous or aggregate information collected from other sources. We may share anonymous information (including aggregate information) we collect regarding our users with third parties (such as advisors or consultants) for various business purposes, including conducting general business analysis, and developing website content and services.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Retention of Personal Information</h3>
          <p className="text-gray-200 mb-6">
            Personal Information is retained to deliver our services, to fulfill identified or apparent purposes, to satisfy regulatory and compliance requirements, and for recordkeeping. At some point, FPX may elect to destroy, erase, or anonymize your Personal Information. Once anonymized, FPX undertakes not to re-identify the information. You have the right to have your Personal Information removed from the Website. You are also able to edit your own account on the Website. If you want your Personal Information deleted from the Website and/or FPX's records, please email FPX at info@fp8.io.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Your Privacy Choices – Limiting Information Sharing / Accessing Your Information</h3>
          <p className="text-gray-200 mb-6">
            You have the right to access the Personal Information that we hold about you, which includes the right to amend or correct that information, as appropriate, and to receive an account of how it has been used and a list of the organizations to whom it has been disclosed. In some circumstances, you may have the right to object to FPX's use of that Personal Information. If you wish to update, amend, or change your Personal Information, please contact us at info@fp8.io or make edits directly to your account. You also have the right to request a copy of the Personal Information you have provided to FPX by emailing info@fp8.io.
          </p>

          <p className="text-gray-200 mb-6">
            To protect the confidentiality of your information and respect your wishes for how it is used, FPX offers "opt out" alternatives. If you have consented to the sharing of your Personal Information at any point, you may instruct us not to share Personal Information about you in the future. As noted above, you may also opt out of receiving future electronic communications from us at any time. You may make your privacy choices at any time, and they will remain in effect until you change them.
          </p>

          <p className="text-gray-200 mb-6">
            If you would like to check, change, or confirm your privacy choices, please contact us at: info@fp8.io.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Protection and Security of Information</h3>
          <p className="text-gray-200 mb-6">
            FPX maintains reasonable standards of confidentiality and security for the Website and for the Personal Information collected from the Website. Physical, technical, electronic, and managerial procedures have been employed to safeguard the security and integrity of Personal Information collected through the Website. The Personal Information you provide is secured using industry-standard security technology. FPX also restricts its own employees' access to Personal Information. However, it is possible that someone may intercept or access communications, transmissions, and/or Personal Information. You must also seek to protect against unauthorized access to any passwords and/or accounts you use in connection with the Website, and you should remember to log off of the Website once you have completed your activities or if you have decided to step away from your computer.
          </p>

          <p className="text-gray-200 mb-6">
            In the event that FPX determines there has been a security breach that results in the unauthorized disclosure of Personal Information to a third party, FPX will promptly notify consumers whose Personal Information it reasonably believes has been compromised by posting information on the Website and, if email contact is possible, by sending an email notification with respect to the situation.
          </p>

          <p className="text-gray-200 mb-6">
            You acknowledge that the security of online transactions you conduct using the Website cannot be guaranteed. To the fullest extent permitted by law, FPX shall not accept responsibility for misuse or loss of, or unauthorized access to, your Personal Information.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Links to Other Websites and Services</h3>
          <p className="text-gray-200 mb-6">
            From time to time, the FPX Website may contain links to other websites or social media features (collectively, "other websites") that are not controlled or maintained by FPX. Please be aware that these other websites may have different privacy policies which may subject you to different privacy practices. Please review the privacy policies of such other websites for information concerning what information is collected on such websites and how it is used. FPX is not responsible for the contents or privacy practices of any linked site or any link on a linked site.
          </p>

          <h3 className="text-xl font-semibold text-gray-200 mb-3">Governing Law</h3>
          <p className="text-gray-200 mb-6">
            This Privacy Policy shall be governed by the laws of the State of New York of the United States of America, without regard to its conflict of law provisions.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Contact Us</h2>
          <p className="text-gray-200 mb-4">
            If you have any questions or complaints concerning this Privacy Policy or our handling of your Personal Information, please contact us by email at:
          </p>
          <a href="mailto:info@fp8.io" className="text-blue-600 hover:text-blue-800">info@fp8.io</a>

          <p className="text-gray-200 mt-6 italic">
            Thank you for taking the time to read this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
