import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/llm/Picture1.png';
import Picture2 from '../assets/llm/Picture2.png';
import Picture3 from '../assets/llm/Picture3.png';
import Picture4 from '../assets/llm/Picture4.jpg';

const InsideResearchllm = () => {
    const tags = ["META", "LLAMA", "OpenAI", "Large Language Model"]
    const comparisonData = [
        {
          criteria: "Pricing",
          closeSource: "Cost typically depends on usage, often involves subscription models.",
          openWeight: "Generally free, though some additional services may incur costs.",
          bestFitCommercial: "Ideal for businesses needing predictable costs.",
          bestFitOpenSource: "Suitable for budget-conscious or flexible projects."
        },
        {
          criteria: "Flexibility & Customization",
          closeSource: "Offers limited customization, mostly standardized solutions.",
          openWeight: "Highly customizable, offering more flexibility.",
          bestFitCommercial: "Best for standard solutions.",
          bestFitOpenSource: "Perfect for projects that need extensive customization."
        },
        {
          criteria: "Security & Compliance",
          closeSource: "Robust security features, compliant with regulations.",
          openWeight: "Security varies and may require extra work for compliance.",
          bestFitCommercial: "Suited for industries with strict security standards.",
          bestFitOpenSource: "Best for custom security adaptations."
        },
        {
          criteria: "Support & Maintenance",
          closeSource: "Comes with structured support and regular maintenance.",
          openWeight: "Community-driven support; quality may vary.",
          bestFitCommercial: "Best for organizations that need dedicated, consistent support.",
          bestFitOpenSource: "Suitable for entities that can self-maintain and leverage community-driven support."
        },
        {
          criteria: "Long-term Viability",
          closeSource: "Backed by commercial entities, ensuring predictable longevity.",
          openWeight: "Depends on community support for continued development.",
          bestFitCommercial: "Perfect for organizations that prioritize long-term stability.",
          bestFitOpenSource: "Great for projects that can adapt to evolving community input and trends."
        }
      ];
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        State of Large Language Models
                    </h2>
                    
                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> This report compares open-source and closed-source AI models, 
                        examining their benefits, challenges, and potential influence on the future AI market.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 10/7/2024
                    </div>

                    <section style={{ marginBottom: '40px' }}>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column - Abstract */}
                            <div>
                                <h2 className="2xl:text-3xl font-semibold mb-6">Abstract</h2>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    This report explores the current state of artificial 
                                    intelligence (AI), focusing on the comparison 
                                    between open-source and closed-source AI 
                                    models. It examines the definitions, differences, 
                                    and advantages of each approach, highlighting 
                                    how open-source models can reduce training 
                                    requirements, enhance security, allow for model 
                                    optimizations, and provide ownership of model 
                                    weights. The report also discusses how these 
                                    factors could potentially shape the future AI 
                                    market.
                                </p>
                            </div>

                            {/* Right Column - Introduction */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">Introduction</h3>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Artificial intelligence has rapidly evolved, 
                                    becoming an integral part of various industries. 
                                    The global AI market size was valued at $119.78 
                                    billion in 2022 and is projected to grow at a CAGR 
                                    of 37.3% from 2023 to 2030. As AI technologies 
                                    advance, the debate between open-source and 
                                    closed-source models intensifies. This report aims 
                                    to provide a comprehensive overview of open-
                                    source and closed-source AI models, their 
                                    differences, and the potential implications for the 
                                    market.
                                </p>
                            </div>
                        </div>

                        {/* Image below the text columns */}
                        <div className="mt-8 flex justify-center">
                            <div className="flex flex-col items-center">
                            <img
                                src={Picture1}
                                alt="AI Market Overview"
                                className=""
                            />
                            <div className="2xl:text-2xl text-gray-300 italic">
                                <div>Source: Epoch AI</div>
                            </div>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Definitions and Background</h2>
                        
                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column - Important Definitions */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">Important Definitions</h3>
                                
                                <div className="mb-8">
                                    <h4 className="2xl:text-3xl font-semibold mb-4">What is a Large Language Model (LLM)?</h4>
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        At its core, a Large Language Model (LLM) is a type of neural 
                                        network designed to predict the next word in a sentence. This 
                                        seemingly simple task is achieved through a process of converting 
                                        input text—referred to as the prompt—into numerical 
                                        representations via a tokenizer. These numbers are then multiplied 
                                        by a series of matrices, known as weights, until the model outputs 
                                        a prediction, which is then converted back into words.
                                    </p>
                                </div>

                                <div>
                                    <h4 className="2xl:text-3xl font-semibold mb-4">Weights</h4>
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        The key to an LLM's power lies in its weights. These weights 
                                        represent patterns the model has learned during the training 
                                        process. During training, the model updates these weights to 
                                        better recognize patterns and improve its ability to make accurate 
                                        predictions. In essence, the model "learns" by adjusting the 
                                        weights through many iterations until it minimizes the error in its 
                                        predictions.
                                    </p>
                                </div>
                            </div>

                            {/* Right Column - Understanding AI Training */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">Understanding AI Training</h3>
                                
                                <div className="mb-8">
                                    <h4 className="2xl:text-3xl font-semibold mb-4">What is AI Training?</h4>
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        Training in AI refers to the process of teaching a machine 
                                        learning model to make accurate predictions or decisions 
                                        based on data. This involves:
                                    </p>
                                    <ul className="2xl:text-3xl 2xl:leading-[2.5rem] list-none pl-4 mt-4">
                                        <li className="mb-2">• Data Input: Feeding the model large datasets 
                                            containing examples relevant to the task.</li>
                                        <li className="mb-2">• Learning Patterns: Adjusting the model's 
                                            parameters so it can recognize patterns and 
                                            relationships within the data.</li>
                                        <li className="mb-2">• Optimization: Minimizing errors by tweaking the 
                                            model to improve its performance over time.</li>
                                    </ul>
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem] mt-4">
                                        The goal is to enable the AI model to generalize from the 
                                        training data to new, unseen data effectively.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Center Image - AI Model Development Process Flow */}
                        <div className="mt-8 flex justify-center">
                            <img
                                src={Picture2}
                                alt="AI Model Development Process Flow"
                                className="w-full max-w-4xl bg-gray-300 p-4 rounded-lg"
                            />
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Open-Source vs Closed Source AI</h2>

                        <div className="grid lg:grid-cols-1 gap-8 mb-8">
                            {/* Left Column - Text Content */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">Open-Source vs Closed Source</h3>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Open-source refers to software whose code is publicly available 
                                    for anyone to use, modify, and distribute. This encourages 
                                    collaboration and transparency.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    In the context of AI, open-source means that the entire AI model, 
                                    including its architecture, code, and sometimes training data, 
                                    is accessible for use, modification, and distribution by anyone.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Closed-source software, on the other hand, restricts access to the 
                                    code, keeping it private, and users can only interact with the 
                                    final product, not modify or inspect it.
                                </p>
                            </div>

                            {/* Right Column - Image */}
                            <div className="flex items-center justify-center">
                                <img
                                    src={Picture3}
                                    alt="Open vs Closed Source Comparison"
                                    className="rounded-lg w-[60%] mx-auto"
                                />
                            </div>
                        </div>

                        {/* Container for Open-Source v/s Open Weights */}
                        <div className="bg-blue-900/30 rounded-lg px-8 py-1 mt-8">
                            <h3 className="2xl:text-3xl font-semibold mb-6 text-center">
                                Open-Source v/s Open Weights
                            </h3>
                            <ul className="2xl:text-3xl 2xl:leading-[2.5rem] space-y-4">
                                <li>• BitOoda distinguishes between open weights and fully open-source models.</li>
                                <li>• Open-weights: AI models like Meta's LLaMA and Microsoft's Phi series make their pre-trained weights public, allowing users to 
                                    download, modify, or fine-tune them.</li>
                                <li>• Fully open-source models: These models release not only the weights but also the entire codebase, including training data and methodologies, ensuring full transparency and reproducibility.</li>
                                <li>• Open-weight models are a subset of open-source models, offering flexibility in performance and security.</li>
                            </ul>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-3xl mb-8">Which is better for you?</h2>
                        <h2 className="2xl:text-4xl mb-8">Open Weights vs Close Weights for your use case</h2>
                        
                        <div className="overflow-x-auto">
                            <table className="min-w-full border border-blue-800">
                            <thead>
                                <tr>
                                <th className="py-3 px-4 bg-blue-900/30 text-left font-semibold border border-blue-800">
                                    Criteria
                                </th>
                                <th className="py-3 px-4 bg-blue-900/30 text-left font-semibold border border-blue-800">
                                    Close Source<br />Commercial Models
                                </th>
                                <th className="py-3 px-4 bg-blue-900/30 text-left font-semibold border border-blue-800">
                                    Open Weight Models
                                </th>
                                <th className="py-3 px-4 bg-blue-900/30 text-left font-semibold border border-blue-800">
                                    Best Fit
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {comparisonData.map((row, index) => (
                                <tr key={index}>
                                    <td className="py-3 px-4 bg-blue-900/30 font-semibold border border-blue-800">
                                    {row.criteria}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-900/30 border border-blue-800">
                                    {row.closeSource}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-900/30 border border-blue-800">
                                    {row.openWeight}
                                    </td>
                                    <td className="py-3 px-4 bg-blue-900/30 border border-blue-800">
                                    <div>
                                        <span className="font-bold italic">Commercial:</span> {row.bestFitCommercial}
                                    </div>
                                    <div className="mt-2">
                                        <span className="font-bold italic">Open source:</span> {row.bestFitOpenSource}
                                    </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-">Performance</h2>
                        <h3 className="2xl:text-3xl font-semibold mb-6">Will Open Source Ever Catch up?</h3>
                        
                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column - Text Content */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-6">Close Source models still have an advantage</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Companies that were pioneers in this space, like Open AI and Google, 
                                    have all kept their models close source to monetize them. Several 
                                    companies, such as Meta and Mistral, have led innovation in the 
                                    open-source space. Still, since training models requires significant 
                                    resources and monetizing open-sourced models is challenging, most 
                                    companies keep their most advanced models close source. Despite 
                                    these breakthroughs by the open-source community, their models 
                                    rapidly caught up to state-of-the-art close-source models.
                                </p>
                            </div>

                            {/* Right Column - Image */}
                            <div className="">
                                <div className=""></div>
                                <img
                                    src={Picture4}
                                    alt="Performance Comparison"
                                    className="rounded-lg w-[60%]"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Closing gap between closed-source and open-weights model</div>
                                    <div>Source: @maximelabonne on X</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchllm;
