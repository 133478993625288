import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/education/Picture1.png';
import Picture2 from '../assets/education/Picture2.png';

const InsideResearchEdu = () => {
    const tags = ["Large Language Model", "Cerebras", "NVIDIA", "GPU", "Inference"]
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        An Educational Analysis of Inference
                    </h2>
                    
                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> This report introduces key AI inference metrics, 
                        throughput, and latency, comparing specialized processors and NVIDIA's H100 GPU 
                        to highlight performance benefits for real-time applications.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 9/25/2024
                    </div>
                    
                    <div className="grid lg:grid-cols-1 gap-8">
                        {/* Abstract Section */}
                        <div className="mb-8">
                            <h3 className="font-bold mb-4 2xl:text-4xl">Abstract</h3>
                            <p className="2xl:text-3xl">
                                In the realm of artificial intelligence (AI), understanding key performance metrics such 
                                as throughput and latency is essential for selecting appropriate hardware for AI inference 
                                tasks. This educational research report introduces these fundamental concepts, 
                                explains the principles of AI inference, and analyzes current hardware offerings, including 
                                specialized processors and NVIDIA's H100 GPUs. By examining performance data for 
                                various processors on the Llama 3.1 models (70 billion (70B) and 8 billion (8B) parameters), 
                                we highlight how specialized hardware can offer superior performance in terms of 
                                throughput and latency compared to general-purpose GPUs for inference.
                            </p>
                        </div>

                        {/* Introduction Section */}
                        <div className="mb-8">
                            <h3 className="font-bold mb-4 2xl:text-4xl">Introduction</h3>
                            <p className="2xl:text-3xl">
                                The exponential growth of AI applications has led to increasing demand for computational 
                                resources. Performance metrics like throughput and latency have become critical 
                                factors in the deployment of AI models, especially during the inference phase. 
                                Inference, the process of using a trained model to make predictions on new data, requires 
                                efficient hardware to meet real-time processing needs.
                            </p>
                            <p className="mt-4 2xl:text-3xl">
                                This report aims to educate readers new to AI on the importance of throughput and latency 
                                in AI inference. We delve into the first principles of AI inference, compare specialized 
                                hardware solutions with general-purpose GPUs like the NVIDIA H100, and analyze 
                                performance data to demonstrate how these concepts apply in practice.
                            </p>
                        </div>
                    </div>

                    {/* New Definitions and Background Section */}
                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Definitions and Background</h2>
                        <h3 className="2xl:text-3xl mb-6">More on Inference</h3>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Understanding AI Inference */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Understanding AI Inference</h4>
                                <h5 className="2xl:text-3xl font-semibold mb-4">What is AI Inference?</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    AI inference applies a trained model to new data to 
                                    generate predictions or outputs. Unlike training, which 
                                    adjusts model parameters, inference uses fixed parameters 
                                    to compute results on input data.
                                </p>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Inference Process</h5>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    <li className="mb-2">• Input Processing: Raw data is preprocessed, e.g., text 
                                        tokenized for language models.</li>
                                    <li className="mb-2">• Model Computation: The input passes through the model, 
                                        using learned weights and biases to generate outputs.</li>
                                    <li className="mb-2">• Output Generation: The output is post-processed into a 
                                        readable format, like converting token probabilities to 
                                        words.</li>
                                </ul>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Factors Affecting Performance</h5>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    <li className="mb-2">• Model Size: Larger models (e.g., 70B vs. 8B parameters) 
                                        require more resources, affecting latency and throughput.</li>
                                    <li className="mb-2">• Hardware Efficiency: Specialized hardware enhances 
                                        performance compared to general-purpose GPUs.</li>
                                    <li className="mb-2">• Software Optimization: Efficient frameworks improve 
                                        memory and computational efficiency.</li>
                                </ul>
                            </div>

                            {/* Important Definitions */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Important Definitions</h4>
                                
                                <h5 className="2xl:text-3xl font-semibold mb-4">Throughput</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Throughput refers to the amount of data processed in a 
                                    given time frame. In AI language models, it is often measured 
                                    in tokens per second. High throughput is crucial for 
                                    applications that require processing large amounts of data 
                                    quickly, such as batch processing or generating long text 
                                    sequences.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Example: A model generating 1,000 tokens per second has 
                                    higher throughput than one generating 500 tokens per 
                                    second.
                                </p>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Latency</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Latency is the time delay from the initiation of a request to 
                                    the delivery of the response. In AI inference, it is commonly 
                                    measured as Time to First Token (TTFT), indicating how 
                                    quickly the model begins to generate output after receiving 
                                    an input.
                                </p>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    <li className="mb-2">• Lower latency is essential for real-time applications like 
                                        interactive chatbots or live translations.</li>
                                    <li className="mb-2">• Higher latency may be acceptable in scenarios where 
                                        immediate responses are not critical.</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    {/* Hardware Analysis Section */}
                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Hardware Analysis</h2>
                        <h3 className="2xl:text-3xl mb-6">Specialized Hardware vs. NVIDIA H100 GPUs</h3>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Advancements in AI hardware have led to the development of specialized processors designed specifically for AI workloads. 
                            These include hardware from companies like Cerebras, SambaNova, and Groq. NVIDIA's H100 GPUs, while powerful, are 
                            general-purpose and may not offer the same level of optimization for specific AI tasks. Cerebras delivers the highest 
                            throughput and lowest latency for both models.
                        </p>

                        {/* Images Container */}
                        <div className="grid lg:grid-cols-1 gap-8">
                            <div className="flex justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <h4 className="2xl:text-3xl font-semibold mb-4">LLAMA 3.1 8B</h4>
                                    <img
                                        src={Picture1}
                                        alt="Hardware Comparison 1"
                                        className="rounded-lg w-full h-auto"
                                    />
                                     <div className="2xl:text-2xl text-gray-300 italic">
                                        <div>Source: Artificial Analysis <a href="https://artificialanalysis.ai/">(https://artificialanalysis.ai/)</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <div className="flex flex-col items-center">
                                    <h4 className="2xl:text-3xl font-semibold mb-4">LLAMA 3.1 70B</h4>
                                    <img
                                        src={Picture2}
                                        alt="Hardware Comparison 2"
                                        className="rounded-lg w-full h-auto"
                                    />
                                    <div className="2xl:text-2xl text-gray-300 italic">
                                        <div>Source: Artificial Analysis <a href="https://artificialanalysis.ai/">(https://artificialanalysis.ai/)</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Specialized Hardware Section */}
                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Specialized Hardware Advantages and Implications</h2>
                        <h3 className="2xl:text-3xl mb-6">Is specialized hardware always better for inference?</h3>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Advantages */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Advantages</h4>
                                
                                <h5 className="2xl:text-3xl font-semibold mb-4">Architectural Optimization</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Specialized hardware is architected specifically for AI 
                                    workloads. This means that the hardware components are 
                                    optimized for the matrix and tensor operations commonly 
                                    used in neural networks, leading to improved performance.
                                </p>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Scalability</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    These hardware solutions are designed to scale efficiently 
                                    with model size. They handle larger models without a 
                                    proportional increase in latency, which is essential as AI 
                                    models continue to grow in complexity.
                                </p>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Energy Efficiency</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Specialized processors often consume less power per 
                                    computation compared to general-purpose GPUs. This 
                                    energy efficiency can lead to cost savings in large-scale 
                                    deployments.
                                </p>
                            </div>

                            {/* Implications */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Implications for AI Practitioners</h4>
                                
                                <h5 className="2xl:text-3xl font-semibold mb-4">Application-Specific Hardware Selection</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Understanding the trade-offs between throughput and 
                                    latency is crucial when selecting hardware for AI 
                                    applications.
                                </p>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    <li className="mb-2">• High Throughput Needs: Applications that require 
                                        processing large volumes of data quickly, such as batch 
                                        data processing or generating extensive text outputs, 
                                        would benefit from hardware with high throughput.</li>
                                    <li className="mb-2">• Low Latency Requirements: Real-time applications like 
                                        conversational AI, live translations, or interactive systems 
                                        need hardware that minimizes latency to provide 
                                        immediate responses.</li>
                                </ul>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Cost Considerations</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    While specialized hardware may have higher initial costs, the 
                                    performance benefits can lead to lower total cost of 
                                    ownership due to reduced energy consumption and the 
                                    need for fewer units to achieve the desired performance.
                                </p>

                                <h5 className="2xl:text-3xl font-semibold mb-4">Software Ecosystem</h5>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Selecting hardware supported by robust software 
                                    frameworks is essential. Compatibility with popular AI 
                                    libraries and tools ensures easier integration and 
                                    development.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchEdu;
