import React from 'react';
import Navbar from '../Navbar.js';
import Consulting from './Consulting.js';
import Footer from '../Footer.js';

function ConsultingPage() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <Consulting />
      </main>
      <Footer />
    </div>
  );
}

export default ConsultingPage;