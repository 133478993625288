import React from 'react';

const EmailPrompt = () => {
  const handleEmailClick = () => {
    const emailSubject = "Request for Comprehensive Data Center TCO Analysis";
    const emailBody = `Hello FPX Team,

I am interested in getting a more comprehensive analysis of my data center TCO calculation. 

Please provide detailed insights on:
- Infrastructure cost optimization
- Power efficiency recommendations
- Cooling solutions analysis
- Hardware configuration optimization
- Long-term TCO projections

Looking forward to your response.

Best regards`;

    const mailtoLink = `mailto:info@fp8.ai?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="flex items-center justify-center space-x-4 px-4 py-1 bg-blue-500 bg-opacity-30 rounded-lg min-h-[45px]">
      <p className="text-white text-sm 2xl:text-xl my-auto">
        Do you want more comprehensive analysis?
      </p>
      <button
        onClick={handleEmailClick}
        className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 py-2 rounded-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 text-sm 2xl:text-xl my-auto"
      >
        Send Mail
      </button>
    </div>
  );
};

export default EmailPrompt; 