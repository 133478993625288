import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/nvidia/Picture1.png';
import Picture2 from '../assets/nvidia/Picture2.png';
import Picture3 from '../assets/nvidia/Picture3.png';
import Picture4 from '../assets/nvidia/Picture4.png';
import Picture5 from '../assets/nvidia/Picture5.png';
import Picture6 from '../assets/nvidia/Picture6.png';
import Picture7 from '../assets/nvidia/Picture7.png';

const InsideResearchNvidia = () => {
    const tags = ["H100", "GPU", "Node", "Server", "Infiniband", "Supermicro", "Dell"]
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        NVIDIA H100: Breaking Down H100 GPUs and Servers
                    </h2>
                    
                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> This report examines NVIDIA's H100 chip and server architecture, 
                        focusing on improvements in tensor performance, memory, and cooling that elevate AI workload 
                        efficiency and scalability.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 10/25/2024
                    </div>

                    <section style={{ marginBottom: '40px' }}>
                        <h3 className="font-bold mb-4 2xl:text-4xl">Abstract</h3>
                        <div className="grid lg:grid-cols-1 gap-8">
                            <div>
                                <p className="2xl:text-2xl">
                                    This report provides an in-depth breakdown of the NVIDIA H100 chip and server architecture, 
                                    emphasizing their roles in advancing AI workloads. It details the H100's Hopper architecture, 
                                    which brings substantial improvements in tensor core performance, memory bandwidth, and power 
                                    efficiency, crucial for handling the intensive demands of large language models (LLMs). The 
                                    report also examines the structure of multi-GPU server nodes, covering components like NVLink 
                                    interconnects, memory systems, and cooling mechanisms that together enable efficient scaling 
                                    and support high-compute AI infrastructure. Through this analysis, the report illustrates how 
                                    the H100 chip and server architecture together set new benchmarks in AI efficiency and 
                                    scalability.
                                </p>
                            </div>
                            <div>
                                <img src={Picture1} alt="NVIDIA H100" className="w-[60%]" />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Figure:  NVIDIA H100 </div>
                                    <div>Source : NVIDIA</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">GPU Primer</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">Why GPUs Are Essential for AI Tasks</h4>
                        
                        <p className="2xl:text-2xl mb-6">
                            Before diving deep into the core components of the H100 chip, it is essential to first understand GPUs, what they 
                            are composed of, and why they are necessary for AI workloads.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            <div>
                                <div>
                                    <h5 className="font-semibold mb-3 2xl:text-2xl">Limitations of CPUs</h5>
                                    <p className="2xl:text-2xl">
                                        Central Processing Units (CPUs) are optimized for 
                                        sequential processing and general-purpose tasks. They 
                                        have a limited number of cores, making them 
                                        inefficient for the highly parallel computations 
                                        required in training and running large language models 
                                        (LLMs). This leads to longer processing times and less 
                                        efficient handling of the massive datasets involved in AI 
                                        workloads.
                                    </p>
                                </div>
                                <div>
                                    <img src={Picture2} alt="CPU vs GPU Comparison" className="w-[60%]" />
                                    <div className="2xl:text-2xl text-gray-300 italic">
                                        <div>CPU vs GPU</div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div>
                                    <h5 className="font-semibold mb-3 2xl:text-2xl">Where GPUs Excel</h5>
                                    <p className="2xl:text-2xl mb-4">
                                        Graphics Processing Units (GPUs), originally built for graphics 
                                        rendering, are specialized hardware accelerators whose 
                                        parallel processing architecture excels at handling vast 
                                        amounts of simultaneous calculations.
                                    </p>
                                    <p className="2xl:text-2xl mb-4">
                                        Graphics Processing Units (GPUs) are engineered for parallel 
                                        processing with thousands of cores, allowing them to perform 
                                        multiple calculations simultaneously.
                                    </p>
                                </div>
                                <div>
                                    <p className="2xl:text-2xl mb-2">This makes GPUs ideal for AI operations:</p>
                                    <ul className="list-disc pl-6 2xl:text-2xl">
                                        <li>Massive Parallelism: Accelerates computations by 
                                            handling numerous operations at once.</li>
                                        <li>Optimized for Deep Learning: Specialized Tensor Cores 
                                            enhance performance for neural network tasks.</li>
                                        <li>High Memory Bandwidth: Enables rapid data access, 
                                            reducing bottlenecks during training and inference.</li>
                                        <li>Scalability: GPUs can be interconnected via NVLink (more 
                                            on this later) to form powerful multi-GPU systems, 
                                            essential for large models.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">What Makes up a GPU Chip</h3>
                        
                        <div>
                            <div>
                                <img src={Picture3} alt="GPU Chip Components" className="w-full bg-gray-300 p-4 rounded-lg" />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Figure: Components of a single GPU chips</div>
                                    <div>Source: NVIDIA</div>
                                </div>
                            </div>
                            <div>
                                <div className="2xl:text-2xl mt-4">
                                    <h4 className="font-semibold mb-3">Processing Units:</h4>
                                    <ul className="list-disc pl-6 mb-4">
                                        <li>Streaming Multiprocessors (SMs): Primary processing blocks containing thousands of CUDA cores and Tensor Cores for parallel computation</li>
                                        <li>CUDA Cores: General-purpose processing units executing parallel calculations across thousands of threads</li>
                                        <li>Tensor Cores: Specialized units optimized for AI/ML matrix operations, crucial for accelerating neural network computations</li>
                                    </ul>

                                    <h4 className="font-semibold mb-3">Memory Hierarchy:</h4>
                                    <ul className="list-disc pl-6 mb-4">
                                        <li>Registers and shared memory for immediate data access within SMs</li>
                                        <li>L1/L2 caches for frequently accessed data</li>
                                        <li>High-Bandwidth Memory (HBM) providing massive bandwidth for data-intensive operations</li>
                                    </ul>

                                    <h4 className="font-semibold mb-3">Communication Infrastructure:</h4>
                                    <ul className="list-disc pl-6 mb-4">
                                        <li>NVLink interfaces for GPU-to-GPU connections</li>
                                        <li>PCIe: high-speed interface standard used for connecting (CPU-GPU communication here)</li>
                                    </ul>

                                    <h4 className="font-semibold mb-3">Control Systems:</h4>
                                    <ul className="list-disc pl-6 mb-4">
                                        <li>Memory controllers managing data flow between cores and memory</li>
                                        <li>Instruction dispatch units scheduling computational tasks</li>
                                        <li>Clock and power management optimizing performance and efficiency</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">Important Facts about the GPU</h3>
                        
                        <div className="mb-8">
                            <h4 className="font-semibold mb-4 2xl:text-3xl">Why is NVLink needed?</h4>
                            <p className="2xl:text-2xl mb-6">
                                NVLink is a high-speed interconnect by NVIDIA that enables rapid data transfer between GPUs and CPUs. 
                                It offers significantly higher bandwidth and lower latency than traditional methods, crucial for 
                                training large language models (LLMs). NVLink ensures efficient scaling across multiple GPUs by 
                                providing fast, low-latency communication, reducing bottlenecks, and accelerating both training 
                                and inference.
                            </p>
                        </div>

                        <div>
                            <h4 className="font-semibold mb-4 2xl:text-3xl">SXM v/s PCIe (comparing for the H100 Chip)</h4>
                            <div className="overflow-x-auto">
                                <table className="w-full border-collapse 2xl:text-2xl">
                                    <thead>
                                        <tr className="bg-blue-900/70">
                                            <th className="border border-gray-400 p-3 text-left">Feature</th>
                                            <th className="border border-gray-400 p-3 text-left">PCIe</th>
                                            <th className="border border-gray-400 p-3 text-left">SXM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border border-gray-400 p-3">Compatibility</td>
                                            <td className="border border-gray-400 p-3">Standard Servers</td>
                                            <td className="border border-gray-400 p-3">High-Performance Computing (HPC) Servers</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 p-3">Memory</td>
                                            <td className="border border-gray-400 p-3">80 GB</td>
                                            <td className="border border-gray-400 p-3">80 GB</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 p-3">GPU Memory Bandwidth</td>
                                            <td className="border border-gray-400 p-3">2 TB/s</td>
                                            <td className="border border-gray-400 p-3">3.35 TB/s</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 p-3">Power Consumption (TDP)</td>
                                            <td className="border border-gray-400 p-3">350W</td>
                                            <td className="border border-gray-400 p-3">Up to 700W</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 p-3">Target Applications</td>
                                            <td className="border border-gray-400 p-3">High-Throughput Data Analytics, Medical Imaging, Interactive Design and Visualization</td>
                                            <td className="border border-gray-400 p-3">Large-Scale AI Model Training and Inference on Massive Datasets, Drug Discovery, and Materials Science</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">H100</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">The Hopper Architecture</h4>
                        
                        <p className="2xl:text-2xl mb-6">
                            The H100 GPUs were announced in 2022 to address the limitations of A100, and provide even more powerful and efficient 
                            solutions for quicker training for bigger models.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            <div>
                                <h4 className="font-semibold mb-4 2xl:text-2xl">H100 advancements over its previous generation</h4>
                                <ul className="list-disc pl-6 2xl:text-2xl space-y-4">
                                    <li>
                                        <strong>Hopper Architecture:</strong> Leverages the Hopper architecture, 
                                        which brings significant improvements in tensor operations, 
                                        memory bandwidth, and overall system efficiency.
                                    </li>
                                    <li>
                                        <strong>Fourth-Generation Tensor Cores:</strong> These new Tensor Cores offer 
                                        enhanced performance for AI and machine learning workloads, 
                                        including support for new data types and instructions.
                                    </li>
                                    <li>
                                        <strong>Improved Power Efficiency:</strong> The H100 GPUs are designed to be 
                                        more power-efficient than A100
                                    </li>
                                    <li>
                                        <strong>Enhanced Interconnects:</strong> Supports advanced interconnects like 
                                        NVLink 4 and InfiniBand, ensuring low-latency and high
                                        throughput communication between nodes.
                                    </li>
                                    <li>
                                        <strong>Increased Memory:</strong> Comes with larger memory capacity, 
                                        including up to 80GB of HBM3 memory, crucial for handling 
                                        large models and datasets.
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="font-semibold mb-4 2xl:text-2xl">H100 vs A100</h4>
                                <div className="overflow-x-auto">
                                    <table className="w-full border-collapse 2xl:text-xl">
                                        <thead>
                                            <tr className="bg-blue-900/70">
                                                <th className="border border-gray-400 p-2 text-left">Specification</th>
                                                <th className="border border-gray-400 p-2 text-left">A100</th>
                                                <th className="border border-gray-400 p-2 text-left">H100</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border border-gray-400 p-2">Architecture</td>
                                                <td className="border border-gray-400 p-2">Ampere</td>
                                                <td className="border border-gray-400 p-2">Hopper</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">Release Time</td>
                                                <td className="border border-gray-400 p-2">2020</td>
                                                <td className="border border-gray-400 p-2">2022</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">Networking</td>
                                                <td className="border border-gray-400 p-2">NVLink: 600 GB/s<br/>PCIe Gen4: 64 GB/s</td>
                                                <td className="border border-gray-400 p-2">NVLink: 900 GB/s<br/>PCIe Gen4: 128 GB/s</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">CUDA Cores</td>
                                                <td className="border border-gray-400 p-2">6912</td>
                                                <td className="border border-gray-400 p-2">16986</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">Highest TDP</td>
                                                <td className="border border-gray-400 p-2">400W (lower)</td>
                                                <td className="border border-gray-400 p-2">700W (higher)</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">GPU Memory</td>
                                                <td className="border border-gray-400 p-2">80 GB HBM2e</td>
                                                <td className="border border-gray-400 p-2">80 GB HBM3e</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">Memory Bandwidth</td>
                                                <td className="border border-gray-400 p-2">2039 Gbps</td>
                                                <td className="border border-gray-400 p-2">3.35 Tbps</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">FP64 Performance</td>
                                                <td className="border border-gray-400 p-2">9.7 TFLOPS</td>
                                                <td className="border border-gray-400 p-2">34 TFLOPS</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-gray-400 p-2">FP64 Tensor Core Performance</td>
                                                <td className="border border-gray-400 p-2">19.5 TFLOPS</td>
                                                <td className="border border-gray-400 p-2">67 TFLOPS</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">H100 Data Center</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">A Lot More Than Just the Chip</h4>
                        
                        <p className="2xl:text-2xl mb-8">
                            Modern AI models like GPT-4 have billions of parameters that demand massive computing power. One GPU can't handle 
                            this alone. A server node compromises around 8 GPUs that work together like a single powerful unit through high-speed 
                            connections. But even this isn't enough for training huge models or serving millions of users, so we need multiple server 
                            nodes in racks. This hierarchical structure (GPU → server node → rack → datacenter cluster) efficiently manages the 
                            extreme computing, power, and cooling needs of AI workloads. We will dive into the inner components of a GPU server 
                            node in this report.
                        </p>

                        <div className="mb-8 bg-gray-300 p-4 rounded-lg">
                            <img src={Picture4} alt="Data Center Hierarchy" className="w-full mb-8" />
                        </div>

                        <div className="grid lg:grid-cols-4 gap-4">
                            <div className="border border-white p-4 rounded-lg">
                                <h5 className="font-semibold mb-3 2xl:text-2xl">GPU</h5>
                                <p className="2xl:text-xl">
                                    A single H100 chip
                                </p>
                            </div>

                            <div className="border border-white p-4 rounded-lg">
                                <h5 className="font-semibold mb-3 2xl:text-2xl">Server Node</h5>
                                <p className="2xl:text-xl">
                                    Complete computing unit containing CPUs, GPUs, memory, and networking 
                                    components that work together to process AI workloads.
                                </p>
                            </div>

                            <div className="border border-white p-4 rounded-lg">
                                <h5 className="font-semibold mb-3 2xl:text-2xl">Rack</h5>
                                <p className="2xl:text-xl">
                                    A rack is a standardized frame (usually 42U tall) that organizes multiple 
                                    chassis vertically with shared power distribution and networking.
                                </p>
                            </div>

                            <div className="border border-white p-4 rounded-lg">
                                <h5 className="font-semibold mb-3 2xl:text-2xl">Cluster</h5>
                                <p className="2xl:text-xl">
                                    A cluster is a collection of networked racks that function as one unified 
                                    computing system, allowing multiple servers to work together on massive AI workloads.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">H100 Server</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">The Computing Brain of AI</h4>
                        
                        <p className="2xl:text-2xl mb-6">
                            We will now break down the main components that makes up a single GPU server node.
                            In a GPU server node, the chassis is the physical framework that houses and organizes internal components like 
                            GPUs, CPUs, power supplies, and storage devices. It provides structural support and facilitates cooling airflow, 
                            ensuring all components are secure and properly cooled.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            <div>
                                <ul className="list-disc pl-6 2xl:text-2xl space-y-4">
                                    <li>
                                        <strong>CPU:</strong> Manages system operations and user 
                                        requests, working with GPUs for efficient workload 
                                        management.
                                    </li>
                                    <li>
                                        <strong>GPUs:</strong> Execute parallel computations required for 
                                        AI and data-intensive tasks.
                                    </li>
                                    <li>
                                        <strong>High-Bandwidth Memory (HBM):</strong> Provides rapid 
                                        data access for GPU computations, crucial for high 
                                        performance.
                                    </li>
                                    <li>
                                        <strong>RAM:</strong> Temporarily stores data for quick CPU 
                                        access, supporting multiple user sessions.
                                    </li>
                                    <li>
                                        <strong>Storage:</strong> High-speed SSDs and NVMe drives that 
                                        securely store large datasets and minimize data 
                                        access times.
                                    </li>
                                    <li>
                                        <strong>Cooling Systems:</strong> Include fans and heat sinks to 
                                        maintain optimal temperatures and ensure 
                                        reliability under heavy workloads.
                                    </li>
                                </ul>
                            </div>
                            
                            <div>
                                <img src={Picture5} alt="H100 Server Components" className="w-full" />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Figure:  DGX H100 Server</div>
                                    <div>Source : NVIDIA</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">H100 Server</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">The Computing Brain of AI (cont.)</h4>

                        <div className="grid lg:grid-cols-1 gap-8">
                            <div>
                                <ul className="list-disc pl-6 2xl:text-2xl space-y-4">
                                    <li>
                                        <strong>Network Interfaces:</strong> High-speed connections 
                                        like Ethernet or InfiniBand for efficient data 
                                        transfer and remote access.
                                    </li>
                                    <li>
                                        <strong>Interconnects (NVLink):</strong> Enable fast 
                                        communication between GPUs, reducing 
                                        latency in multi-GPU workloads.
                                    </li>
                                    <li>
                                        <strong>PCIe:</strong> High-speed interface connecting GPUs 
                                        and peripherals to the motherboard, providing 
                                        necessary bandwidth.
                                    </li>
                                    <li>
                                        <strong>NVSwitch:</strong> NVIDIA Specific PCIe that is much 
                                        faster and can connect nodes to enable faster 
                                        communication between GPUs at scale.
                                    </li>
                                    <li>
                                        <strong>Power Supply Units (PSUs):</strong> Supply stable 
                                        power to all components, handling high power 
                                        demands.
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <img src={Picture7} alt="H100 Server Additional Components" className="w-full" />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Figure: Components GPU tray components in an NVIDIA DGX H100 system.</div>
                                    <div>Source: NVIDIA</div>
                                </div>
                            </div>
                        </div>
                        <div className="2xl:text-2xl mb-6 mt-4 bg-blue-900/70 px-4 py-2 rounded-lg">
                            <strong>Important:</strong> Ethernet typically operates at speeds from 10Gbps to 400Gbps with microsecond latency, while 
                            InfiniBand delivers higher performance with speeds from 100Gbps to 800Gbps and much lower nanosecond 
                            latency, making it preferred for GPU-to-GPU communication in AI clusters despite its higher cost.
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">What are the various types of Nodes?</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">H100 DGX vs HGX</h4>
                        
                        <p className="2xl:text-2xl mb-6">
                            As defined previously a node is a server unit housed on a rack in the data center. It typically consists of 4 or 8 H100s 
                            along with CPUs, Storage and Networking/ Interconnect ports allowing it to communicate with the rest of the cluster. 
                            It is a modular unit allowing for easier expansion.
                        </p>

                        <div className="space-y-6 2xl:text-2xl">
                            <ul className="list-disc pl-6 space-y-4">
                                <li>
                                    <strong>NVIDIA DGX H100:</strong> NVIDIA's template for an ideal H100 datacenter node, featuring 8 H100 SXM GPUs, dual Intel 
                                    Xeon 8480C CPUs, 4 NV Switches, and support for up to 400Gb/s InfiniBand/Ethernet, with a 10.2kW TDP. It's non
                                    customizable, set directly by NVIDIA as a template for certified chassis makers.
                                </li>
                                <li>
                                    <strong>NVIDIA HGX H100:</strong> Built by NVIDIA certified manufacturers like Dell, Supermicro, and HPE, these servers also use 
                                    H100 SXM GPUs but offer customizable options, such as 4-GPU configurations, Intel or AMD CPUs, and power 
                                    efficiency tweaks, allowing for flexibility in networking and power needs compared to the fixed DGX.
                                </li>
                            </ul>

                            <div className="mt-6">
                                <h5 className="font-semibold mb-4">There are additional H100 Node types for smaller tasks:</h5>
                                
                                <ul className="list-disc pl-6 space-y-4">
                                    <li>
                                        <strong>NVIDIA EGX:</strong> This is even more modular, it features the grace hopper superchip (H100 GPU + CPU in one chip) The 
                                        focus is power efficiency and modularity for smaller workloads.
                                    </li>
                                    <li>
                                        <strong>NVIDIA MGX:</strong> This is the server based on the PCIe version of the h100 with various configurable networking, 
                                        cooling and CPU options. This is the lowest cost option.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section className="mb-10">
                        <h3 className="font-bold mb-4 2xl:text-4xl">H100 Server Manufactures</h3>
                        <h4 className="font-semibold mb-4 2xl:text-3xl">What is Best for You?</h4>
                        
                        <div className="w-full bg-white">
                            <img src={Picture6} alt="H100 Server Manufacturers Comparison" className="w-full" />
                        </div>
                    </section>

                    

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchNvidia;
