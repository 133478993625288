import './App.css';
import Home from './components/home/Home';
import OurTeamPage from './components/ourteam/OurTeamPage';
import ResearchPage from './components/research/ResearchPage';
import ConsultingPage from './components/consulting/ConsultingPage';
import InsideResearch from './components/research/InsideResearch';
import AddPost from './components/admin/AddPost';
import Login from './components/Login';
import Register from './components/Register';
import PostDetail from './components/admin/PostDetail';
import React, { createContext, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import ResearchPapaerOverviews from './components/admin/ResearchPapaerOverviews';
import InsideResearchPapaerOverviews from './components/admin/InsideResearchPapaerOverviews';
import ContractPapaerOverviews from './components/admin/ContractPapaerOverviews';
import InsideContractPapaerOverviews from './components/admin/InsideContractPapaerOverviews';
import SinglePage from './components/admin/SinglePage';
import Tco from './components/datacentertco/Tco1';
import InsideResearch1 from './components/research/InsideResearch1';
import InsideResearchAgentic from './components/research/InsideResearchAgentic';
import InsideResearchCloud from './components/research/InsideResearchCloud';
import InsideResearchArt from './components/research/InsideResearchArt';
import InsideResearchCrash from './components/research/InsideResearchCrash';
import InsideResearchllm from './components/research/InsideResearchllm';
import InsideResearchModerAi from './components/research/InsideResearchModerAi';
import InsideResearchEdu from './components/research/InsideResearchEdu';
import InsideResearchNvidia from './components/research/InsideResearchNvidia';
import Modelspage from './components/models/Modelspage';
import SampleSpotContract from './components/admin/SampleSpotContract';
import FPXPrinciples from './components/FPXPrinciples';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsofUse';
import Disclosures from './components/Disclosures';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

export const AuthContext = createContext(null);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSuperUser, setSuperUser] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('access');
    const superuser = localStorage.getItem('superuser');
    setIsAuthenticated(!!token);
    setSuperUser(!!superuser);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('superuser');
    setIsAuthenticated(false);
    setSuperUser(false);
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, handleLogout, isSuperUser, setSuperUser }}>
      <Router>
        <div className="app-container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin/addpost" element={<AddPost />} />
            <Route path="/ourteam" element={<OurTeamPage />} />
            <Route path="/research-overviews" element={<ResearchPage />} />
            <Route path="/research-overviews/:id" element={<InsideResearch />} />
            <Route path="/research-overviews/9" element={<InsideResearch1 />} />
            <Route path="/research-overviews/8" element={<InsideResearchAgentic />} />
            <Route path="/research-overviews/7" element={<InsideResearchCloud />} />
            <Route path="/research-overviews/6" element={<InsideResearchNvidia />} />
            <Route path="/research-overviews/5" element={<InsideResearchArt />} />
            <Route path="/research-overviews/4" element={<InsideResearchCrash />} />
            <Route path="/research-overviews/3" element={<InsideResearchllm />} />
            <Route path="/research-overviews/2" element={<InsideResearchModerAi />} />
            <Route path="/research-overviews/1" element={<InsideResearchEdu />} />
            <Route path="/consulting" element={<ConsultingPage />} />
            <Route path="/posts/:id" element={<PostDetail />} />
            <Route path="/research" element={<ResearchPapaerOverviews />} />
            <Route path="/research/:title" element={<InsideResearchPapaerOverviews />} />
            <Route path="/contract" element={<ContractPapaerOverviews />} />
            <Route path="/contract/:title" element={<InsideContractPapaerOverviews />} />
            <Route path="/contract/sample-spot-contract" element={<SampleSpotContract />} />
            <Route path="/whitepaper" element={<SinglePage />} />
            <Route path="/consulting/datacenter/tco" element={<Tco />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/disclosures" element={<Disclosures />} />
            <Route path="/models" element={<Modelspage />} />
            <Route path="/fpx-principles" element={<FPXPrinciples />} />
          </Routes>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;