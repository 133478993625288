// File: src/components/Chatbot/index.jsx
import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Send, Minimize2, Maximize2 } from 'lucide-react';
import { OpenAI } from '@langchain/openai';
import { BufferMemory } from 'langchain/memory';
import { ConversationChain } from 'langchain/chains';
import ReactMarkdown from 'react-markdown';

// Constants for the chatbot's behavior and responses
const SYSTEM_PROMPT = `I am an FP8 AI assistant specialized in helping users understand and analyze data center Total Cost of Ownership (TCO) calculations. I can help with:

1. Explaining calculations for:
   - Capital Expenditure (CapEx) including infrastructure, building costs, and initial equipment
   - Operational Expenditure (OpEx) including power, cooling, maintenance, and staffing
   - Revenue projections for both full ownership and colocation models

2. Interpreting dashboard results and metrics:
   - Breaking down cost components
   - Explaining ROI calculations
   - Comparing ownership vs colocation costs
   - Analyzing profit margins and break-even points

3. Understanding key financial metrics:
   - Net Present Value (NPV)
   - Internal Rate of Return (IRR)
   - Payback period
   - Total Cost of Ownership (TCO)

4. Best practices for:
   - Data center capacity planning
   - Cost optimization strategies
   - Infrastructure scaling decisions
   - Maintenance and operational efficiency

5. Detailed component breakdown:
   - Power infrastructure costs
   - Cooling system expenses
   - Network equipment investments
   - Facility maintenance costs
   - Staffing and personnel expenses

I aim to provide clear, accurate explanations and help users make informed decisions about their data center investments.`;

const FAQ_PAIRS = {
  "How is the net profit calculated?": "Net Profit = Total Revenue - (CapEx + OpEx)\n\nThe calculation considers:\n\n1. **Total Revenue**\n- Service fees\n- Hosting charges\n- Additional services\n\n2. **Capital Expenditure (CapEx)**\n- Infrastructure costs\n- Equipment purchases\n- Initial setup\n\n3. **Operational Expenditure (OpEx)**\n- Power consumption\n- Cooling costs\n- Maintenance fees\n- Staff salaries",
  
  "What is included in total CapEx?": "**Total Capital Expenditure (CapEx)** includes:\n\n1. **Building/Facility Costs**\n- Construction expenses\n- Land acquisition\n- Design and planning\n\n2. **Infrastructure**\n- Power systems\n- Cooling equipment\n- Security systems\n\n3. **IT Equipment**\n- Servers and storage\n- Network hardware\n- Backup systems\n\n4. **Initial Setup**\n- Installation costs\n- Software licenses\n- Professional services",
  
  "What comprises the monthly OpEx?": "**Monthly Operational Expenditure (OpEx)** consists of:\n\n1. **Utility Costs**\n- Power consumption\n- Cooling operations\n- Water usage\n\n2. **Maintenance**\n- Regular repairs\n- Equipment updates\n- Preventive maintenance\n\n3. **Personnel**\n- Staff salaries\n- Training costs\n- Benefits\n\n4. **Services**\n- Network connectivity\n- Security monitoring\n- Insurance",
};

const GREETING_RESPONSES = {
  "hi": "Hi there! I'm your data center TCO assistant. How can I help you today?",
  "hello": "Hello! I'm here to help with your data center TCO questions. What would you like to know?",
  "hey": "Hey! How can I assist you with data center TCO calculations today?",
  "good morning": "Good morning! I'm ready to help with any data center TCO questions you might have.",
  "good afternoon": "Good afternoon! How can I assist you with data center TCO today?",
  "good evening": "Good evening! I'm here to answer your data center TCO questions."
};

const GENERAL_RESPONSES = {
  "capex": "**Capital Expenditure (CapEx)** refers to the upfront investment in physical assets for a data center.\n\nThis includes:\n\n1. Building costs\n2. Infrastructure setup\n3. Equipment purchases\n4. Initial software licenses\n\nWhat specific aspects of CapEx would you like to know about?",
  
  "opex": "**Operational Expenditure (OpEx)** covers the ongoing costs of running a data center.\n\nKey components include:\n\n1. Power consumption\n2. Cooling systems\n3. Maintenance and repairs\n4. Staff salaries\n\nWhat specific aspects of OpEx would you like to learn more about?",
  
  "tco": "**Total Cost of Ownership (TCO)** is a comprehensive financial estimate that includes:\n\n1. Initial CapEx investments\n2. Ongoing OpEx costs\n3. Maintenance and upgrades\n4. End-of-life considerations\n\nWhich aspect of TCO would you like to explore?"
};

// MessageContent component for rendering individual messages
const MessageContent = ({ content, type }) => {
  const markdownComponents = {
    p: ({ children }) => <p className="mb-3">{children}</p>,
    strong: ({ children }) => <span className="font-bold text-gray-900">{children}</span>,
    ul: ({ children }) => <ul className="ml-4 space-y-2 list-disc mb-3">{children}</ul>,
    ol: ({ children }) => <ol className="ml-4 space-y-2 list-decimal mb-3">{children}</ol>,
    li: ({ children }) => <li className="mb-1">{children}</li>,
  };

  return (
    <div
      className={`max-w-[80%] p-3 rounded-lg ${
        type === 'user'
          ? 'bg-blue-500 text-white'
          : 'bg-gray-100 text-gray-800'
      }`}
    >
      {type === 'bot' ? (
        <ReactMarkdown
          className="prose prose-sm max-w-none"
          components={markdownComponents}
        >
          {content}
        </ReactMarkdown>
      ) : (
        content
      )}
    </div>
  );
};

// Main Chatbot component
const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: 'bot',
      content: "Hi, I'm your FPX AI assistant! I can help you understand the data center TCO calculator and answer general questions. How can I help you today?"
    }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const chatModel = useRef(null);
  const memory = useRef(null);
  const chain = useRef(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    const initLangChain = async () => {
      try {
        chatModel.current = new OpenAI({
          modelName: "gpt-4o-mini",
          temperature: 0.7,
          maxTokens: 500,
          openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
        });
        
        memory.current = new BufferMemory();
        
        chain.current = new ConversationChain({
          llm: chatModel.current,
          memory: memory.current,
        });
        
        isInitialized.current = true;
      } catch (error) {
        console.error('Error initializing LangChain:', error);
        isInitialized.current = false;
      }
    };

    initLangChain();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const MessageContent = ({ content, type }) => {
    const markdownComponents = {
      // Headings with proper spacing
      h1: ({ children }) => <p className="font-bold mb-3 mt-2">{children}</p>,
      h2: ({ children }) => <p className="font-bold mb-3 mt-2">{children}</p>,
      h3: ({ children }) => <p className="font-bold mb-3 mt-2">{children}</p>,
      h4: ({ children }) => <p className="font-bold mb-3 mt-2">{children}</p>,
      
      // Regular paragraphs with proper spacing
      p: ({ children }) => <p className="mb-3">{children}</p>,
      
      // Bold text
      strong: ({ children }) => <span className="font-bold">{children}</span>,
      
      // Lists with minimal right padding
      ul: ({ children }) => <ul className="mb-3 mt-2 space-y-2 ">{children}</ul>,
      ol: ({ children }) => <ol className="mb-3 mt-2 space-y-2">{children}</ol>,
      
      // List items with minimal right padding and proper bullet alignment
      li: ({ children }) => (
        <li className=" flex items-start">
          <span className="mr-1 inline-block">•</span>
          <span className="flex-1">{children}</span>
        </li>
      ),
    };
  
    return (
      <div
        className={`max-w-[80%] p-3 rounded-lg ${
          type === 'user'
            ? 'bg-blue-500 text-white'
            : 'bg-gray-100 text-gray-800'
        }`}
      >
        {type === 'bot' ? (
          <ReactMarkdown
            className="text-sm space-y-2"
            components={markdownComponents}
          >
            {content}
          </ReactMarkdown>
        ) : (
          content
        )}
      </div>
    );
  };
  
  const formatResponse = (text) => {
    // Format terms with their abbreviations
    text = text.replace(/([A-Za-z\s]+) \((CapEx|OpEx|TCO|NPV|IRR)\)/g, '**$1 ($2)**');
    
    // Ensure lists have proper line breaks with minimal padding
    text = text.replace(/([^-])\n•/g, '$1\n\n•'); // Add extra line break before bullets
    text = text.replace(/•\s*/g, '\n• '); // Ensure each bullet starts on new line
    
    // Ensure proper spacing after colons before lists
    text = text.replace(/([^:\n]+):\s*\n\s*-\s+/g, '$1:\n\n• ');
    
    // Add proper spacing between sections
    text = text.replace(/([.!?])\s+([A-Z])/g, '$1\n\n$2');
    
    // Format numbered lists with proper spacing
    text = text.replace(/(\d+\.)\s+([^\n]+)/g, '\n$1 $2\n');
    
    // Remove any triple line breaks
    text = text.replace(/\n\n\n+/g, '\n\n');
    
    return text.trim();
  };

  const checkExactFAQ = (question) => {
    const normalizedQuestion = question.toLowerCase().trim();
    for (const [key, value] of Object.entries(FAQ_PAIRS)) {
      if (key.toLowerCase() === normalizedQuestion) {
        return value;
      }
    }
    return null;
  };

  const checkGreeting = (message) => {
    const normalized = message.toLowerCase().trim();
    for (const [greeting, response] of Object.entries(GREETING_RESPONSES)) {
      if (normalized === greeting || normalized.startsWith(greeting + " ")) {
        return response;
      }
    }
    return null;
  };

  const checkGeneralTopics = (message) => {
    const normalized = message.toLowerCase().trim();
    for (const [topic, response] of Object.entries(GENERAL_RESPONSES)) {
      if (normalized.includes(topic)) {
        return response;
      }
    }
    return null;
  };

  const handleSend = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = {
      type: 'user',
      content: inputMessage
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsTyping(true);

    try {
      let botResponse = checkExactFAQ(inputMessage) ||
                       checkGreeting(inputMessage) ||
                       checkGeneralTopics(inputMessage);

      if (!botResponse && isInitialized.current) {
        try {
          const response = await chain.current.call({
            input: `${inputMessage}\n\nPlease provide a response based on your knowledge of data center TCO calculations and financial metrics. Format the response with proper Markdown for headers, lists, and emphasis. ${SYSTEM_PROMPT}`
          });
          
          if (response && response.response) {
            botResponse = formatResponse(response.response);
          } else {
            botResponse = "I apologize, but I couldn't generate a specific answer for that question. Could you please rephrase or ask about specific aspects of data center TCO?";
          }
        } catch (apiError) {
          console.error('API Error:', apiError);
          botResponse = "I'm having trouble connecting to my knowledge base. I can still answer questions about CapEx, OpEx, TCO, colocation, and other basic data center concepts. How else can I help you?";
        }
      }

      setMessages(prev => [...prev, {
        type: 'bot',
        content: botResponse || "I apologize, but I'm not sure how to help with that specific question. Could you please ask about something related to data center TCO, CapEx, OpEx, or colocation costs?"
      }]);
    } catch (error) {
      console.error('Error in message handling:', error);
      setMessages(prev => [...prev, {
        type: 'bot',
        content: "I apologize for the technical difficulty. I can still answer basic questions about data center TCO, CapEx, OpEx, and colocation costs. What would you like to know?"
      }]);
    }

    setIsTyping(false);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen && (
        <div 
          className={`bg-white rounded-lg shadow-xl flex flex-col transition-all duration-300 ease-in-out ${
            isExpanded 
              ? 'w-[40vw] h-[90vh]' 
              : 'w-96 h-[500px]'
          }`}
        >
          <div className="p-4 bg-blue-500 text-white rounded-t-lg flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <MessageCircle />
              <div>
                <div className="font-semibold">FP8 AI Assistant</div>
                <div className="text-xs">Online</div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button 
                onClick={toggleExpand} 
                className="text-white hover:text-white/80 hidden md:block"
              >
                {isExpanded ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
              </button>
              <button 
                onClick={() => setIsOpen(false)} 
                className="text-white hover:text-white/80"
              >
                <X />
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <MessageContent content={message.content} type={message.type} />
              </div>
            ))}
            {isTyping && (
              <div className="flex justify-start">
                <div className="bg-gray-100 text-gray-800 p-3 rounded-lg">
                  Thinking...
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className="p-4 border-t">
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                placeholder="Type your message..."
                className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSend}
                className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      )}

      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center shadow-lg hover:bg-blue-600 transition-colors"
        >
          <MessageCircle className="text-white" />
        </button>
      )}
    </div>
  );
};

export default Chatbot;