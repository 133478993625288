import React, { useEffect, useState } from "react";
import Modal from "../modal";
import bullet_icon from "../assets/bullet_icon.png"

function OurTeamPage() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Fetch team members data
    fetch("/assets/ourteam.json")
      .then((response) => response.json())
      .then((data) => setTeamMembers(data))
      .catch((error) => console.error("Error fetching team data:", error));
  }, []);

  const openModal = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMember(null);
  };

  return (
    <div className="py-20 px-6 min-h-screen text-white bg-gradient-to-br from-[#00237D] via-black to-[#00237D]">
      <h1 className="text-5xl md:text-6xl lg:text-8xl text-white text-center mb-10">Our Team</h1>
      <div className="w-full md:max-w-[90%] lg:max-w-[80%] mx-auto grid grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 gap-3 md:gap-6 lg:gap-8">
    {teamMembers.map((member) => (
      <div
        key={member.id}
        className="px-3 py-3 md:px-6 md:py-4 bg-gradient-to-br from-[#00237db7] via-black to-[#00237db7] rounded-lg shadow-lg overflow-hidden border border-gray-200"
      >
        {/* Profile Image */}
        <div className="w-full flex items-start justify-start mb-3 md:mb-4">
          <img
            src={member.profileUrl}
            alt={member.name}
            className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full object-cover border-2 md:border-4 border-white shadow-lg"
          />
        </div>

        {/* Profile Details */}
        <div className="pb-4 md:pb-6">
          <h2 className="text-lg md:text-xl lg:text-2xl 2xl:text-4xl font-semibold mb-1 md:mb-2 text-start">{member.name}</h2>
          <p className="text-gray-300 text-sm md:text-lg lg:text-xl 2xl:text-3xl mb-2 md:mb-4 text-start">{member.post}</p>
          <button
            onClick={() => openModal(member)}
            className="w-full text-white font-medium text-sm md:text-base 2xl:text-2xl py-1.5 md:py-2 px-2 md:px-4 rounded-md bg-purple transition-all duration-500 shadow-[2px_2px_0px_hsl(0,0%,90%),4px_4px_0px_hsl(0,0%,80%),6px_6px_0px_hsl(0,0%,70%),8px_8px_0px_hsl(0,0%,60%),10px_10px_0px_hsl(0,0%,50%),12px_12px_0px_hsl(0,0%,40%),14px_14px_0px_hsl(0,0%,30%),16px_16px_0px_hsl(0,0%,20%),18px_18px_0px_hsl(0,0%,10%)] hover:bg-[#241d52cd] hover:text-white hover:shadow-none"
          >
            View Full Profile
          </button>
        </div>
      </div>
    ))}
  </div>

      {/* Modal for Full Profile */}
      {isModalOpen && selectedMember && (
        <Modal.ModalTeamContainer onClose={closeModal}>
          {/* Profile Section - Image on left, Name/Post on right */}
          <div className="flex flex-col md:flex-row items-center gap-[12%] mb-6">
            {/* Profile Image */}
            <div className="flex-shrink-0">
              <img
                src={selectedMember.profileUrl}
                alt={selectedMember.name}
                className="w-40 h-40 md:w-56 md:h-56 2xl:w-72 2xl:h-72 rounded-full object-cover border-4 border-[#00237D] shadow-lg"
              />
            </div>

            {/* Name, Post and Social Icons */}
            <div className="flex flex-col items-start justify-center mt-4 md:mt-0">
              <h2 className="text-3xl md:text-4xl 2xl:text-6xl font-bold">{selectedMember.name}</h2>
              <p className="text-xl md:text-2xl 2xl:text-5xl text-gray-300 mt-2">{selectedMember.post}</p>
              
              {/* Social Media Icons */}
              <div className="flex items-center gap-4 mt-4">
                {selectedMember.linkedinUrl && (
                  <a 
                    href={selectedMember.linkedinUrl} 
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="text-gray-400 hover:text-[#0077B5] transition-colors"
                  >
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.454C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.225 0z" />
                    </svg>
                  </a>
                )}
                {selectedMember.twitterUrl && (
                  <a 
                    href={selectedMember.twitterUrl} 
                    target="_blank"
                    rel="noopener noreferrer" 
                    className="text-gray-400 hover:text-[#1DA1F2] transition-colors"
                  >
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 10.055 10.055 0 01-3.127 1.184 4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="pt-4">
            <ul className="list-none space-y-4">
              {selectedMember.description.map((point, index) => (
                <li
                  key={index}
                  className="text-base md:text-lg 2xl:text-4xl flex items-start gap-3 text-gray-400"
                >
                  <img
                    src={bullet_icon}
                    alt="Bullet Icon"
                    className="w-5 h-5 md:w-6 md:h-6 object-contain mt-1 flex-shrink-0"
                  />
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          </div>
        </Modal.ModalTeamContainer>
      )}
    </div>
  );
}

export default OurTeamPage;
