import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const SampleSpotContract = () => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center bg-gradient-to-br from-[#18034f] via-black to-[#18034f] text-white min-h-screen">
        <div className="py-[4rem] px-[1rem] md:px-[7rem] lg:px-[10rem] xl:px-[15rem]">
            <div className="flex flex-col items-start w-full lg:w-[70%] pt-4 md:pt-10">
            <div className="w-full">
                <h1 className="text-2xl sm:text-3xl md:text-4xl 2xl:text-6xl font-bold mb-4 md:mb-8 2xl:pt-20">
                FLOP Contract Research
                </h1>
            </div>
            </div>

            <div className="w-full 2xl:max-w-[120rem] prose prose-invert max-w-none">
            <p className="mb-6">
                We have to divide AI into a Training and an Inference Contract
            </p>
            <p className="mb-6">
                Training/Finetuning/ Synthetic data generation = Crude(WTI) Contract
            </p>
            <p className="mb-6">
                Inference = Power contract
            </p>
            <p className="mb-6">
                3 tiers per contract
            </p>
            <p className="mb-8">
                3 sub contracts per tier based on framework (CUDA vs ...)
            </p>

            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-6">
                PROPOSED FP8 AI TRAINING FLOP FUTURES CONTRACT SPEC
            </h2>

            <div className="overflow-x-auto mb-10">
                <table className="min-w-full border-collapse border border-purple-800">
                <thead>
                    <tr className="bg-purple-900/70">
                    <th className="border border-purple-800 p-3 text-left">CONTRACT SPECIFICATIONS</th>
                    <th className="border border-purple-800 p-3 text-left">DESCRIPTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Description</td>
                    <td className="border border-purple-800 p-3">The FP8 AI Training contract is a deliverable contract based on EFP delivery with an option to cash settle</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Symbol</td>
                    <td className="border border-purple-800 p-3">A</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Contract Size</td>
                    <td className="border border-purple-800 p-3">1 contract = 4 Compute Units</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Units</td>
                    <td className="border border-purple-800 p-3">
                        Compute Units (24 Hours of 1 H100 SXM Node (Throughput of 8 SOTA Chips updated every year) = 2736 exaflop per day (Dec 2024) example of 2030
                        <br /><br />
                        For future flop units please refer to the Whitepaper
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Currency</td>
                    <td className="border border-purple-800 p-3">U.S. dollars and cents</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Trading Price</td>
                    <td className="border border-purple-800 p-3">One cent ($0.01) per Compute Unit</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Settlement Price</td>
                    <td className="border border-purple-800 p-3">One cent ($0.01) per Compute Unit</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Minimum Price Fluctuation</td>
                    <td className="border border-purple-800 p-3">One cent ($0.01) per Compute Unit</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Trading Hours</td>
                    <td className="border border-purple-800 p-3"></td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Tenor</td>
                    <td className="border border-purple-800 p-3">ATC</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Contract Price</td>
                    <td className="border border-purple-800 p-3">
                        Spot : Dynamic
                        <br /><br />
                        Futures : Fixed
                        <br /><br />
                        Dynamic market price for spot pay as you go<br />
                        Fixed 2 USD per hour for futures
                        <br /><br />
                        On-peak vs off-peak tenors, ATC (around the clock)
                        <br /><br />
                        Reserve / pre-negotiated + surge/add, take-or-pay<br />
                        Price falls if latency, uptime requirements are not met
                        <br /><br />
                        <span className="bg-purple-800/30 px-1">Need to fix language -- get rid of the term "dynamic"</span>
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Uptime Guarantee</td>
                    <td className="border border-purple-800 p-3">
                        99% Uptime
                        <br /><br />
                        Broken down by Hardware Uptime
                        <br /><br />
                        Failure Rates
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Payment Terms</td>
                    <td className="border border-purple-800 p-3">
                        Spot: Pay as you go
                        <br /><br />
                        Futures: 20% Upfront payment at contract purchase<br />
                        Pay rest as you go at a fixed price based on futures market
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Software</td>
                    <td className="border border-purple-800 p-3">Bare metal vs pre-loaded specific software/OS</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Storage Requirements</td>
                    <td className="border border-purple-800 p-3">
                        Static: 500 GB
                        <br /><br />
                        Dynamic increases as needed
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Settlement</td>
                    <td className="border border-purple-800 p-3">
                        • Cash Settled<br />
                        • API Endpoint will be provided
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Capacity Utilization Factor (Urgency)</td>
                    <td className="border border-purple-800 p-3">
                        100% = immediate execution; 0% = have entire contract period for Compute seller to execute job and job can be interruptible
                        <br /><br />
                        Add in job urgency vs latency
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Data Security</td>
                    <td className="border border-purple-800 p-3">
                        Certificates
                        <br /><br />
                        Data transfer encryption
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Deviation</td>
                    <td className="border border-purple-800 p-3">Penalty: Flop Deviation = $/flop Latency Deviation = ?</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Terminate contract</td>
                    <td className="border border-purple-800 p-3"></td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Validation</td>
                    <td className="border border-purple-800 p-3">Validation ....</td>
                    </tr>
                </tbody>
                </table>
            </div>

            <p className="mb-4">
                Monthly contracts: Last trading day is the 3<sup>rd</sup> business day following the last calendar day of the month.
            </p>

            <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">Settlement price:</h3>

            <ul className="list-disc pl-5 mb-8 space-y-2">
                <li>For On-Peak contracts: The final settlement price is the average of the On-Demand price 7am- 11pm M-F</li>
                <li>For Off-Peak contracts: Similarly, the final settlement price is the average of the On-Demand price 11pm -- 7 am M-F All hours on Weekends</li>
                <li>For ATC contracts: Similarly, the final settlement price is the average of the On-Demand price All hours everyday</li>
                <li>Related contracts - Weeklies, Rest of Week, Bal-day</li>
            </ul>

            <p className="mb-8">
                The settlement price is determined by the exchange at 3pm on the final trading day.
            </p>

            <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">Settlement Process</h3>

            <p className="mb-4">
                Once the final settlement price is determined:
            </p>

            <ol className="list-decimal pl-5 mb-8 space-y-2">
                <li>The Exchange calculates the difference between the final settlement price and the original contract price for each position.</li>
                <li>Profits or losses are then credited or debited to each trader's corresponding margin account</li>
                <li>The final settlement (payment) occurs on the first business day following the Last Trading Day</li>
                <li>After the cash settlement is complete, the contract is considered closed, and there are no further obligations between the contracting parties</li>
            </ol>

            <p className="mb-8">
                This process ensures that all monthly contracts are settled based on the actual market prices observed during the contract period, providing an accurate reflection of the underlying compute market conditions.
            </p>

            <p className="mb-4">
                These are cash settled contracts.
            </p>

            <p className="mb-4">
                Validation
            </p>

            <p className="mb-4">
                Might look at more frequent expirations (weekly or daily)
            </p>

            <p className="mb-4">
                Trading Parameters Trading Hours
            </p>

            <p className="mb-8">
                Validation-DB question DA-multiple ways to do this
            </p>

            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-6">
                PROPOSED FP8 AI INFERENCE FLOP FUTURES CONTRACT SPEC
            </h2>

            <div className="overflow-x-auto mb-10">
                <table className="min-w-full border-collapse border border-purple-800">
                <thead>
                    <tr className="bg-purple-900/70">
                    <th className="border border-purple-800 p-3 text-left">CONTRACT SPECIFICATIONS</th>
                    <th className="border border-purple-800 p-3 text-left">DESCRIPTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Description</td>
                    <td className="border border-purple-800 p-3">The FP8AI Training contract is a deliverable contract based on EFP delivery with an option to cash settle</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Symbol</td>
                    <td className="border border-purple-800 p-3">A</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Contract Size</td>
                    <td className="border border-purple-800 p-3">1 contract = 64 Compute Units</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Units</td>
                    <td className="border border-purple-800 p-3">
                        Compute Units (24 Hours of 1 H100 SXM Node (Throughput of 8 SOTA Chips updated every year) = 2736 exaflop
                        <br /><br />
                        The underlying value of compute needs to stay constant
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Currency</td>
                    <td className="border border-purple-800 p-3">U.S. dollars and cents</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Trading Price</td>
                    <td className="border border-purple-800 p-3">Five cents ($0.05) per Compute Unit</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Settlement Price</td>
                    <td className="border border-purple-800 p-3">Five cents ($0.05) per Compute Unit</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Minimum Price Fluctuation</td>
                    <td className="border border-purple-800 p-3">Five cents ($0.05) per Compute Unit</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Trading Hours</td>
                    <td className="border border-purple-800 p-3"></td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Contract Tenor</td>
                    <td className="border border-purple-800 p-3">
                        ATC
                        <br /><br />
                        3 month exercise period, quarterly windows (Jan -- March, April -- June, July -- Sep, Oct -- Dec), where compute can be bought in that window
                        <br /><br />
                        Expand past 3 month windows; duplicate commodity markets into dailies, weeklies, monthlies, quarterlies, strips / calendars
                        <br /><br />
                        <span className="bg-purple-800/30 px-1">Updates to do: Refer to ICE power contracts -- get rid of the 3 month statements (for DB -- align w Power)</span>
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Contract Price</td>
                    <td className="border border-purple-800 p-3">
                        Spot : Dynamic
                        <br /><br />
                        Futures : Fixed
                        <br /><br />
                        Dynamic market price for spot pay as you go<br />
                        Fixed 2 USD per hour for futures
                        <br /><br />
                        On-peak vs off-peak tenors, ATC (around the clock)
                        <br /><br />
                        Reserve / pre-negotiated + surge/add, take-or-pay<br />
                        Price falls if latency, uptime requirements are not met
                        <br /><br />
                        <span className="bg-purple-800/30 px-1">Need to fix language -- get rid of the term "dynamic"</span>
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Latency Requirements</td>
                    <td className="border border-purple-800 p-3">
                        End to end Latency: 15ms<br />
                        Network Latency: 5ms<br />
                        Processing Latency: 4ms<br />
                        # Inter agent Latency: 2ms
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Uptime Guarantee</td>
                    <td className="border border-purple-800 p-3">99% Uptime</td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Locations</td>
                    <td className="border border-purple-800 p-3">Guaranteed latency in these locations</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Payment Terms</td>
                    <td className="border border-purple-800 p-3">
                        Spot: Pay as you go
                        <br /><br />
                        Futures: 20% Upfront payment at contract purchase<br />
                        Pay rest as you go at a fixed price based on futures market
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Software</td>
                    <td className="border border-purple-800 p-3">Bare metal vs pre-loaded specific software/OS</td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Storage Requirements</td>
                    <td className="border border-purple-800 p-3">
                        Static: 500 GB
                        <br /><br />
                        Dynamic increases as needed
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Settlement Method</td>
                    <td className="border border-purple-800 p-3">
                        • Cash Settled<br />
                        • API Endpoint will be provided
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Capacity Utilization Factor (Urgency)</td>
                    <td className="border border-purple-800 p-3">
                        100% = immediate execution; 0% = have entire contract period for Compute seller to execute job and job can be interruptible
                        <br /><br />
                        Add in job urgency vs latency
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Data Security</td>
                    <td className="border border-purple-800 p-3">
                        Certificates
                        <br /><br />
                        Data transfer encryption
                    </td>
                    </tr>
                    <tr>
                    <td className="border border-purple-800 p-3 font-medium">Dispute Resolution / Breach of Contract</td>
                    <td className="border border-purple-800 p-3">
                        Penalty?<br />
                        Buy from spot market?<br />
                        Consortium step in?
                    </td>
                    </tr>
                    <tr className="bg-purple-900/20">
                    <td className="border border-purple-800 p-3 font-medium">Terminate contract</td>
                    <td className="border border-purple-800 p-3"></td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SampleSpotContract;