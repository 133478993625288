import React from 'react';

const FPXPrinciples = () => {
  return (
    <div className="bg-gradient-to-br from-[#001550] via-[#0b1530] to-[#001550] min-h-screen pt-24 pb-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">FPX Principles and Philosophies</h1>
          <div className="w-24 h-1 bg-blue-500 mx-auto rounded-full"></div>
        </div>

        <div className="prose max-w-none">
          <ul className="list-none text-gray-100 space-y-6 px-4 md:px-10">
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Believe you can make the world a better place and act on it each day</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Act with urgency</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Ship everyday</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Do what you love each day</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Fall in love with what you do</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">No one is bigger than the customer</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">God is in the details</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">A lot of things matter a little</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">A few things matter a lot</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Feedback is fuel</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Excellence, not perfection</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Consistency breeds excellence</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Take accountability</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Do what you say you will</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Taste is a muscle</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Grow a little every day</span>
            </li>
            <li className="flex items-start group">
              <span className="mr-4 text-blue-400 text-xl font-bold">•</span>
              <span className="text-xl font-medium group-hover:text-blue-300 transition-colors duration-200">Embrace discomfort</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FPXPrinciples;
