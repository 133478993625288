import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ContactConsulting } from "./ContactConsulting";

export default function Consulting() {
  const [consultings, setConsultings] = useState([]);
  const [activeService, setActiveService] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch('/assets/consulting.json')
      .then(response => response.json())
      .then(data => {
        const fixedData = data.map(item => ({
          ...item,
          keyBenefits: item.keyBenefits || item.keyBenfits || []
        }));
        setConsultings(fixedData);
        if (fixedData.length > 0) {
          setActiveService(fixedData[0]);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading consultings:', error);
        setIsLoading(false);
      });
  }, []);

  const selectService = (service) => {
    setActiveService(service);
    setIsMobileMenuOpen(false);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-950 to-indigo-950">
        <div className="animate-pulse text-white text-xl">Loading services...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-950 to-indigo-950">
      <div className="container mx-auto px-4 pt-28 pb-16">
        <h1 className="text-4xl md:text-5xl font-bold text-white text-center mb-12">
          Consulting Services
        </h1>
        
        {/* Mobile services list - replacing the dropdown */}
        <div className="md:hidden mb-6">
          <div className="flex flex-col mb-6">
            {consultings.map((service, index) => (
              <button
                key={index}
                onClick={() => selectService(service)}
                className={`px-4 py-2 rounded-lg text-left transition-all w-full
                  ${activeService && activeService.title === service.title 
                    ? "bg-blue-800 text-white border-2 border-blue-400" 
                    : "bg-blue-900/30 text-blue-100 border border-blue-800/30 hover:bg-blue-800/50"
                  }`}
              >
                <span className="font-medium text-sm truncate block">{service.title}</span>
              </button>
            ))}
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row md:gap-8 md:items-start">
          {/* Sidebar (desktop only) with enhanced animations */}
          <div className="hidden md:block md:w-1/4 lg:w-1/5 flex-shrink-0">
            <div className="bg-blue-900/30 backdrop-blur-sm rounded-lg border border-blue-800/30 overflow-hidden sticky top-24">
              {consultings.map((service, index) => (
                <motion.button
                  key={index}
                  onClick={() => selectService(service)}
                  className={`w-full text-left p-4 border-l-4 transition-all
                            ${activeService && activeService.title === service.title 
                              ? "border-blue-400 bg-blue-800/40 text-white" 
                              : "border-transparent hover:border-blue-400/50 hover:bg-blue-800/20 text-blue-100"}`}
                  whileHover={{ x: 5 }}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.05 }}
                >
                  {service.title}
                </motion.button>
              ))}
            </div>
          </div>
          
          {/* Main content with enhanced animations */}
          <div className="md:w-3/4 lg:w-4/5">
            <AnimatePresence mode="wait">
              {activeService && (
                <motion.div
                  key={activeService.title}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                  className="bg-blue-900/20 backdrop-blur-sm border border-blue-800/30 rounded-lg p-6 md:p-8 shadow-lg"
                >
                  <div className="space-y-8">
                    <div>
                      <h2 className="text-3xl font-bold text-white mb-4">{activeService.title}</h2>
                      <p className="text-gray-200 leading-relaxed">{activeService.description}</p>
                    </div>
                    
                    <div>
                      <h3 className="text-xl font-semibold text-white mb-4">Components</h3>
                      <div className="grid gap-4 sm:grid-cols-2">
                        {activeService.tags.map((tag, index) => (
                          <motion.div 
                            key={index} 
                            className="bg-blue-800/30 rounded-lg p-5 border border-blue-700/30"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                          >
                            <div className="flex items-center mb-2">
                              <span className="inline-block bg-blue-700 text-blue-50 text-sm font-medium px-3 py-1 rounded">
                                {tag}
                              </span>
                            </div>
                            <p className="text-gray-300">{activeService.tagscontent[index]}</p>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                    
                    {activeService.keyBenefits && activeService.keyBenefits.length > 0 && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                      >
                        <h3 className="text-xl font-semibold text-white mb-4">Key Benefits</h3>
                        <div className="bg-blue-800/20 rounded-lg p-5 border border-blue-700/30">
                          <ul className="grid gap-3 sm:grid-cols-2">
                            {activeService.keyBenefits.map((benefit, index) => (
                              <motion.li 
                                key={index} 
                                className="flex items-start"
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: 0.3 + (index * 0.05) }}
                              >
                                <span className="text-blue-300 mr-2 mt-1">✓</span>
                                <span className="text-gray-200">{benefit}</span>
                              </motion.li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                    
                    {activeService && activeService.id === 1 ? (
                      <div className="mt-8 flex justify-center">
                        <a 
                          href="/consulting/datacenter/tco"
                          className="inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-3 rounded-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 text-lg font-semibold no-underline"
                        >
                          TCO Calculator
                        </a>
                      </div>
                    ) : (
                      <ContactConsulting />
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}