import React from 'react';

const TermsofUse = () => {
  return (
    <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="mt-10 px-10 text-white">
        <h1 className="text-3xl font-bold text-white mb-4">Terms of Use</h1>
        <p className="text-sm text-gray-100 mb-8">Last Updated February 25, 2025</p>

        <div className="prose max-w-none">
          <p className="text-gray-200 mb-6">
            This website is operated by, or on behalf of, FPX, Inc. ("FPX," "we," or "us").
          </p>

          <p className="text-gray-200 mb-6">
            Please read these Terms of Use ("Terms of Use") carefully and ensure you understand them. These Terms of Use, including all documents referenced herein, represent the entire understanding and agreement between FPX and you regarding your use of this Website and supersede any prior statements or representations. FPX's [Privacy Policy] is incorporated into these Terms of Use by reference and is made a part hereof. You are not authorized to use the Website if you do not agree to all of these Terms of Use.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Changes to the Terms of Use</h2>
          <p className="text-gray-200 mb-6">
            From time to time, these Terms of Use may change. FPX reserves the right to modify these Terms of Use without prior individual notice. We will post changes to the Terms of Use on this Website, and those changes will become effective once posted. Your continued access to or use of the Website will be deemed acceptance by you of the then-current Terms of Use. Please review these Terms of Use often so that you will remain abreast of our current policies.
          </p>

          <p className="text-gray-200 mb-6">
            You can tell when the Terms of Use were last modified by checking the “Last Updated” date that appears at the top.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Use of this Website</h2>
          <p className="text-gray-200 mb-6">
            You must be 18 years of age to use this Website. FPX makes no representation that this Website, or the materials contained hereon, are appropriate for users located in other countries. Those who choose to access this Website from countries outside the United States do so on their own initiative and are responsible for compliance with all laws in that country, if and to the extent that such laws are applicable.
          </p>

          <p className="text-gray-200 mb-6">
            Access to, distribution, and/or use of this Website is subject to all applicable laws and regulations. To the extent that access, distribution, and/or use of this Website would be deemed illegal by applicable law, such access, distribution, and/or use is prohibited. Each time you visit any area on the Website and/or register for any interactive functionality, you are deemed to have confirmed your acceptance of these Terms of Use and the Website's Privacy Policy. If you do not agree to abide by these Terms of Use and the Privacy Policy, please do not use this Website.
          </p>

          <p className="text-gray-200 mb-6">
            By participating in the activities offered on this Site, you affirm, represent, and warrant that:
          </p>
          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">You are 18 years of age or older and reside in the United States;</li>
            <li className="mb-2">All information you submit via the Website shall be truthful and accurate (and you will maintain the accuracy of such information);</li>
            <li className="mb-2">You will abide by these Terms of Use; and</li>
            <li className="mb-2">Your use of the Website shall not otherwise violate any applicable law, rule, or regulation.</li>
            <li className="mb-2">FPX may terminate your right to access and/or use this Website at any time.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Use of Materials</h2>
          <p className="text-gray-200 mb-6">
            Materials on this Website, including but not limited to images, reports, trademarks, text, and graphics (collectively referred to as the "Materials"), are protected under various intellectual property laws and are owned or controlled by FPX, or used with permission of the owner(s) of such Materials. The display of Materials on this Website does not imply that a license of any kind has been granted to you.
          </p>
          <p className="text-gray-200 mb-6">
            In order to access some features of the Website and subscribe to services or products offered by FPX, you may have to create an account. When creating an account, you must provide accurate and complete information, and you may not impersonate another person or otherwise misrepresent your identity.
          </p>
          <p className="text-gray-200 mb-6">
            Except as otherwise indicated on this Website, copying, reproducing, uploading, downloading, transmitting or any other use of this Website or of any of the Materials, in whole or part, without the express permission of FPX, is strictly prohibited. You may download digital files only where expressly permitted. Any use of information or Materials from this Website must cite FPX as the source and clearly identify FPX in any visuals used. Any unauthorized access to, use of, or copying of this Website and/or the Materials may subject you to liability under applicable law and may result in legal action.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Availability of Product Offerings</h2>
          <p className="text-gray-200 mb-6">
            Not all of the products or services referenced on this Website may be available to all individuals accessing this Website. Certain products or services may not be available in all areas of the United States, and certain individuals may not be eligible for all of the product offerings referenced on this Site. Subject to applicable law, FPX retains the right to determine each individual's eligibility for such products or services.
          </p>

          <p className="text-gray-200 mb-6">
            You are not allowed to access or use the service if you are located, incorporated or otherwise established in, or a citizen or resident of:
          </p>
          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">Any state, country or other jurisdiction that is embargoed by the United States of America;</li>
            <li className="mb-2">A jurisdiction where it would be illegal according to applicable law for you (by reason of your nationality, domicile, citizenship, residence, or otherwise) to access or use the service; or</li>
            <li className="mb-2">Any jurisdiction where the publication or availability of the service is prohibited or contrary to local law or regulation.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Research Disclosures</h2>
          <p className="text-gray-200 mb-6">
            Any research, analysis, commentary, or other similar materials provided on this Website ("Research Materials") is intended for general informational purposes only. Research Materials may include forward-looking statements, opinions, assumptions, or estimates. While FPX strives to provide reliable content, we do not guarantee the accuracy, completeness, or timeliness of any Research Materials.
          </p>

          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">The information provided on this Website is not intended as, and shall not be construed to be, legal, tax, financial, or investment advice.</li>
            <li className="mb-2">Past performance of any products, services, or markets referenced in our Research Materials does not indicate or guarantee future results.</li>
            <li className="mb-2">All users are encouraged to conduct their own research and due diligence and, where applicable, seek professional advice before making any decisions based on the Research Materials.</li>
            <li className="mb-2">FPX does not hold any special licenses (e.g., as a broker-dealer, commodity trading advisor, investment advisor) and does not purport to act in any such capacity.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Links</h2>
          <p className="text-gray-200 mb-6">
            The FPX Website may contain links to other websites or social media features (collectively, “other sites”) that are not controlled or maintained by FPX. Access to and use of such other sites is at your own risk and subject to any terms of use and privacy policies which govern those sites. By providing such links, FPX shall not be deemed to endorse, recommend, approve, guarantee, or introduce any third parties or their services or products, or any facts, views, advice, information, promotions, and/or products found on other sites. FPX is not responsible for the content contained on other sites, for the failure of any product or service offered for sale or advertised on any other sites, or for any damages that may result therefrom. Copyright in the materials or information on the linked sites is owned and/or controlled by other organizations. Such other sites may have privacy policies or terms of use that differ from those of this Website and/or may provide users with less security than this Website. You should review the privacy policies and terms of use on such other sites before using them.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Account Registration and Password Responsibility</h2>
          <p className="text-gray-200 mb-6">
            In order to access and subscribe to and/or purchase the products and services offered on this Website, you may be required to register, create an account, and choose a password. As part of the process for creating such an account and password, you will be asked to confirm that you have read, consent to, and agree to be bound by these Terms of Use (including our Privacy Policy).
          </p>

          <ul className="list-disc text-gray-200 mb-6 ml-8">
            <li className="mb-2">You are responsible for maintaining the confidentiality of your account and password.</li>
            <li className="mb-2">You agree not to (i) use the account, username, or password of another user at any time; (ii) share your account; or (iii) disclose your password to any third party.</li>
            <li className="mb-2">You agree to notify FPX immediately if you suspect any unauthorized access to or use of your account or password.</li>
            <li className="mb-2">You are solely responsible for any and all use of your account and password.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Non-Commercial Use</h2>
          <p className="text-gray-200 mb-6">
            You may not use the Website or any Materials provided herein in connection with any commercial endeavors except those that are specifically endorsed or approved by FPX.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Additional/Different Terms</h2>
          <p className="text-gray-200 mb-6">
            Certain pages or sections of this Website may contain terms of use that are in addition to or different from these Terms of Use. In the event there is a conflict between such additional or different terms and these Terms of Use, the additional or different terms will govern with respect to the specific pages or sections to which they apply.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">No Specific Advice Is Provided by this Website</h2>
          <p className="text-gray-200 mb-6">
            This Website is for general informational purposes and is not intended to provide specific legal, accounting, tax, financial, or other professional advice to any particular user. You should consult with appropriate legal advisors or other professionals with respect to any investment, retirement, or other financial decisions.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Limitation of Liability and Use Disclaimer</h2>
          <p className="text-gray-200 mb-6">
            FPX endeavors to ensure that all information and data it originates on the Website is accurate. However, FPX is not responsible for any damages or loss related to the use of this Website or the products/services provided through the Website.
          </p>

          <p className="text-gray-200 mb-6 uppercase">
            THIS WEBSITE, ALL MATERIAL CONTAINED ON THIS WEBSITE, AND ALL LINKS OR OTHER ITEMS RELATED THERETO ARE TRANSMITTED AND DISTRIBUTED "AS IS," WITHOUT GUARANTEES, REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED (BY STATUTE OR OTHERWISE).
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Your Responsibilities</h2>
          <p className="text-gray-200 mb-6">
            You are responsible for undertaking suitable precautions to scan for computer viruses and for maintaining a backup of all data and/or equipment.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Indemnity</h2>
          <p className="text-gray-200 mb-6">
            Unless prohibited by law, you agree to indemnify, defend, and hold harmless FPX and its officers, directors, members, employees, representatives, licensees, agents, successors, assigns, and partners, from and against any and all claims, demands, losses, liabilities, damages, costs, and expenses (including but not limited to attorneys' fees) suffered, incurred, or brought against any one or more of them by a third party relating to or arising from:
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Communications</h2>
          <p className="text-gray-200 mb-6">
            With respect to all communications you make to or through the Website, including but not limited to feedback, questions, comments, and suggestions:
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Copyright Infringement – Notice and Take-Down Policy</h2>
          <p className="text-gray-200 mb-6">
            FPX values intellectual property and respects the intellectual property rights of others, and will remove materials on its Website that infringe the copyrights of others. If you believe that your copyrighted material has been infringed by material contained on this Website, you should provide the following notice to FPX:
          </p>

          <p className="text-gray-200 mb-4">Email: <a href="mailto:info@fp8.io" className="text-blue-600 hover:text-blue-800">info@fp8.io</a></p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">U.S. Export Controls</h2>
          <p className="text-gray-200 mb-6">
            Software or products obtained through or related to this Website may be subject to United States export controls or the export controls of other countries from where you access the Site. No software or products may be downloaded from the Website or otherwise exported or reexported in violation of U.S. or other applicable export laws. Downloading or using the software or products is at your sole risk.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mt-8 mb-4">Disputes and Governing Law</h2>
          <p className="text-gray-200 mb-6">
            Use of this Website and these Terms of Use shall be governed by the laws of the State of New York of the United States of America, without regard to its conflict of law provisions. In the event any portion of these Terms of Use is deemed unenforceable, unlawful, or void by a tribunal of competent jurisdiction, such portion shall be deemed modified to the extent necessary to make it enforceable, and such determination shall not affect the remaining provisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsofUse;
