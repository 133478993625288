import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactConsulting = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_f60f0no",
        "template_2xh80gg",
        form.current,
        "fRf_9tEs1A934fEMU"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response.status, response.text);
          form.current.reset();
        },
        (error) => {
          console.error("Email failed to send:", error);
        }
      );
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-xl font-bold text-gray-800 mb-4">Contact Us</h2>
      
      <form ref={form} onSubmit={sendEmail} className="space-y-3">
        <div>
          <label htmlFor="user_name" className="block text-sm font-medium text-gray-700 mb-1">
            Name
          </label>
          <input
            id="user_name"
            name="user_name"
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
          />
        </div>
        
        <div>
          <label htmlFor="user_email" className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            id="user_email"
            name="user_email"
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
          />
        </div>
        
        <div>
          <label htmlFor="user_website" className="block text-sm font-medium text-gray-700 mb-1">
            Company/Portfolio Link (Optional)
          </label>
          <input
            id="user_website"
            name="user_website"
            type="text"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
        
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            rows="3"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            required
          />
        </div>
        
        <button
          type="submit"
          className="w-full bg-blue-600 text-white font-medium py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-1"
        >
          Submit
        </button>
      </form>
    </div>
  );
};