import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import image from '../assets/image.png';

function ModelItem({ model, onClick }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);

  return (
    <motion.div
      ref={ref}
      style={{
        scale: scaleProgress,
        opacity: opacityProgress,
        transition: "transform 0.5s ease, opacity 0.5s ease",
      }}
      className="card-gradient w-full max-w-[600px] border border-purple-500/20 rounded-lg overflow-hidden p-6 hover:bg-purple-900/30 transition text-white cursor-pointer relative"
    >
      <div className="flex flex-col h-full justify-between">
        <div className="relative w-full h-[450px] mb-4 rounded-lg overflow-hidden">
          <img
            src={model.image}
            alt={model.title}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div>
          <h3 className="text-2xl 2xl:text-4xl font-semibold mb-4 text-center">{model.title}</h3>
          <p className="leading-relaxed text-gray-200 mb-4 text-center 2xl:text-2xl">
            {model.description}
          </p>
        </div>
        <div className="date-container">
          <button
            onClick={() => onClick(model)}
            className="bg-gradient-to-r from-[#00237d99] to-[#00237d99] text-white 2xl:text-2xl font-semibold py-3 px-4 rounded w-full hover:from-[#00237db6] hover:to-[#00237db6] transition-all duration-300 focus:ring-2 focus:ring-purple-500 focus:outline-none"
          >
            Explore
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default function Modelspage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredModels, setFilteredModels] = useState([]);

  const models = [
    {
      id: 1,
      title: "TCO Calculator",
      description: "Calculate the Total Cost of Ownership for your data center infrastructure",
      image: image,
      path: "/consulting/datacenter/tco"
    },
    // Add more models here as needed
  ];

  useEffect(() => {
    const items = models.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredModels(items);
  }, [searchQuery]);

  const handleModelClick = (model) => {
    navigate(model.path);
  };

  const clearSearch = () => setSearchQuery("");

  return (
    <div className="page-gradient flex flex-col items-center py-20 text-white min-h-screen">
      <div className="text-center mb-8 mt-10">
        <h1 className="text-5xl 2xl:text-6xl 2xl:pb-8 font-bold">Models</h1>
        <p className="text-lg 2xl:text-3xl mt-2">Interactive models and calculators from the FPX team.</p>
      </div>

      <div className="card-gradient p-6 rounded-lg w-full max-w-3xl mb-10 mx-4">
        <div className="flex justify-between items-center">
          <label className="block text-lg 2xl:text-3xl 2xl:pb-2 mb-2">Search</label>
          <button onClick={clearSearch} className="text-gray-300 2xl:text-2xl hover:text-white transition-colors">
            Clear
          </button>
        </div>
        <input
          type="text"
          placeholder="Search all models"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 2xl:text-2xl rounded-lg bg-gray-800 text-white border border-purple-500/20 focus:border-purple-500 transition-all duration-300 outline-none"
        />
      </div>

      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 px-5">
        {filteredModels.map((model) => (
          <ModelItem key={model.id} model={model} onClick={handleModelClick} />
        ))}
      </div>
    </div>
  );
}
