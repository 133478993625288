import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";

function ResearchItem({ research, onClick }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });
  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Use a stable cache key instead of changing timestamp
  // This allows browser to cache the image properly
  const cacheKey = research.id.toString();
  // Add a size parameter to request a lower resolution version
  const imageUrl = `${research.imageLink}?size=medium&cache=${cacheKey}`;

  return (
    <motion.div
      ref={ref}
      style={{
        scale: scaleProgress,
        opacity: opacityProgress,
        transition: "transform 0.5s ease, opacity 0.5s ease",
      }}
      className="card-gradient w-full max-w-[600px] border border-purple-500/20 rounded-lg overflow-hidden p-6 hover:bg-purple-900/30 transition text-white cursor-pointer relative"
    >
      <div className="flex flex-col h-full justify-between">
        <div className="relative w-full h-[450px] mb-4 rounded-lg overflow-hidden">
          {!imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800">
              <p className="text-white">Loading...</p>
            </div>
          )}
          <img
            key={cacheKey}
            src={imageUrl}
            alt={research.title}
            className={`w-full h-full object-cover rounded-lg ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            loading="lazy"
            decoding="async"
            onLoad={() => setImageLoaded(true)}
            onError={(e) => {
              console.log(`Failed to load image: ${imageUrl}`);
              e.target.src = "/assets/placeholder-image.jpg";
              setImageLoaded(true);
            }}
          />
        </div>
        <div>
          <h3 className="text-2xl 2xl:text-4xl font-semibold mb-4 text-center">{research.title}</h3>
          <p className="leading-relaxed text-gray-200 mb-4 text-center 2xl:text-2xl">
            {research.description}
          </p>
        </div>
        <div className="date-container">
          <p className="text-gray-300 2xl:text-2xl text-sm mb-4">
            Post date: {new Date(research.date).toLocaleDateString()}
          </p>
          <button
            onClick={() => onClick(research)}
            className="bg-gradient-to-r from-[#00237d99] to-[#00237d99] text-white 2xl:text-2xl font-semibold py-3 px-4 rounded w-full hover:from-[#00237db6] hover:to-[#00237db6] transition-all duration-300 focus:ring-2 focus:ring-purple-500 focus:outline-none"
          >
            Read More
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default function Research() {
  const [researchItems, setResearchItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    // Use a stable cache key for the JSON file
    // This will allow the browser to cache the JSON properly
    fetch(`/assets/research.json?v=1.0`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Loaded research data:", data);
        const sortedData = data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
        setResearchItems(sortedData);
        setFilteredItems(sortedData);
        setIsLoading(false);
        
        // Preload the first few images
        sortedData.slice(0, 6).forEach(item => {
          const img = new Image();
          img.src = `${item.imageLink}?size=medium&cache=${item.id}`;
        });
      })
      .catch((error) => console.error("Error loading research data:", error));
  }, []);

  useEffect(() => {
    let items = researchItems.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    if (sortOrder) {
      items = items.slice().sort((a, b) => {
        return sortOrder === "desc"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
    }
  
    setFilteredItems(items);
  }, [searchQuery, sortOrder, researchItems]);

  const openResearchDetails = (research) => {
    if (research.id === 9) {
      navigate(`/research-overviews/9`);
    } 
    else if (research.id === 8) {
      navigate(`/research-overviews/8`);
    }
    else if (research.id === 7) {
      navigate(`/research-overviews/7`);
    }
    else if (research.id === 6) {
      navigate(`/research-overviews/6`);
    }
    else if (research.id === 5) {
      navigate(`/research-overviews/5`);
    }
    else if (research.id === 4) {
      navigate(`/research-overviews/4`);
    }
    else if (research.id === 3) {
      navigate(`/research-overviews/3`);
    }
    else if (research.id === 2) {
      navigate(`/research-overviews/2`);
    }
    else if (research.id === 1) {
      navigate(`/research-overviews/1`);
    }
    else {
      navigate(`/research-overviews/${research.id}`, { state: { research } });
    }
  };

  const clearSearch = () => setSearchQuery("");

  return (
    <div className="page-gradient flex flex-col items-center py-20 text-white min-h-screen">
      <div className="text-center mb-8 mt-10">
        <h1 className="text-5xl 2xl:text-6xl 2xl:pb-8 font-bold">Market Education</h1>
        <p className="text-lg 2xl:text-3xl mt-2">Market insights from the FPX team.</p>
      </div>

      <div className="card-gradient p-6 rounded-lg w-full max-w-3xl mb-10 mx-4">
        <div className="flex justify-between items-center">
          <label className="block text-lg 2xl:text-3xl 2xl:pb-2 mb-2">Search</label>
          <button onClick={clearSearch} className="text-gray-300 2xl:text-2xl hover:text-white transition-colors">
            Clear
          </button>
        </div>
        <input
          type="text"
          placeholder="Search all articles"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 2xl:text-2xl rounded-lg bg-gray-800 text-white border border-purple-500/20 focus:border-purple-500 transition-all duration-300 outline-none"
        />
        <div className="mt-4 flex items-center justify-between">
          <button
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            className="flex items-center text-gray-300 hover:text-white font-semibold transition-colors 2xl:text-2xl"
          >
            {sortOrder === "asc" ? <FaSortAmountDownAlt className="mr-2" /> : <FaSortAmountUpAlt className="mr-2" />}
            <span>Sort by Date</span>
          </button>
        </div>
      </div>

      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 px-5">
        {filteredItems.map((research, index) => (
          <ResearchItem key={index} research={research} onClick={openResearchDetails} />
        ))}
      </div>
    </div>
  );
}