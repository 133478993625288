import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/crash/Picture1.jpg';
import Picture2 from '../assets/crash/Picture2.jpg';
import Picture3 from '../assets/crash/Picture3.png';
import Picture4 from '../assets/crash/Picture4.png';
import Picture5 from '../assets/crash/Picture5.png';


const InsideResearchCrash = () => {
    const tags = ["META", "Mining", "AI Hardware", "Cerebras", "Groq", "Large Language Model"]
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        The Crash of the H100 Market
                    </h2>
                    

                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> This report examines recent shifts in the GPU market, 
                        focusing on changing demand for NVIDIA's H100 amid technological advances, open-source 
                        AI models, and market recalibrations.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 10/16/2024
                    </div>
                    
                    <section style={{ marginBottom: '40px' }}>
                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h2 className="2xl:text-4xl mb-8">Abstract</h2>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    The graphics processing unit (GPU) market has 
                                    undergone significant changes in recent years, 
                                    driven by technological advancements in AI, 
                                    shifting demand patterns, and economic factors. 
                                    This report will delve into the current state of GPU 
                                    pricing, focusing on the over-investment in high-
                                    end GPUs like the H100, the impact of open-
                                    source models, and the subsequent market 
                                    adjustments.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <h2 className="2xl:text-4xl mb-8">Introduction</h2>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                                    In 2023, the NVIDIA H100 GPU became the go-to 
                                    choice for training large-scale AI models, with 
                                    rental prices soaring to as much as $8 per hour. 
                                    However, by 2024, several key developments 
                                    rapidly altered this market dynamic.
                                </p>
                                
                            </div>
                            
                        </div>
                        <img
                                    src={Picture1}
                                    alt="H100 Market Dynamics"
                                    className="w-[60%] rounded-lg"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>The H100 is marketed to be far superior to than NVIDIAprevious generation of chips.</div>
                                    <div>Source: NVIDIA</div>
                                </div>

                    </section>
                    
                    <section style={{ marginBottom: '40px' }}>
                    <h2 className="2xl:text-4xl mb-8">2023: Start of the AI Hype</h2>
                        <div className="grid lg:grid-cols-1 gap-8">
                           
                            {/* Left Column */}
                            <div>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    In 2023, the NVIDIA H100 GPU, designed specifically for training 
                                    large-scale AI models, dominated the market with astronomical 
                                    rental prices, soaring as high as $8 per hour. For AI startups and 
                                    data centers alike, the H100 was seen as an essential tool for 
                                    training cutting-edge models, and the overwhelming demand for 
                                    AI training fueled both high prices and shortages in availability.
                                </p>
                                <img
                                    src={Picture2}
                                    alt="H100 Market in 2023"
                                    className="w-[35%] rounded-lg mt-8"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>H100 GPU Shipments</div>
                                    <div>Source: Omedia Research</div>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    The general sentiment was that this demand would continue to 
                                    climb, and many believed the price of H100s would remain high. 
                                    The H100's superior compute power, especially for AI training 
                                    tasks, and the proliferation of large-scale model training created a 
                                    market environment where renting H100 GPUs felt like a necessity 
                                    to stay competitive in the race to develop Artificial General 
                                    Intelligence (AGI). This demand was bolstered by the capital 
                                    pouring into AI startups, pushing GPU clusters into a price bubble 
                                    where the expectation was that H100 pricing would remain 
                                    elevated for the foreseeable future.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mt-8">
                                    However, that market dynamic underwent a rapid transformation 
                                    in 2024, as several key events changed the landscape.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">2024: The Turning Point</h2>
                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h3 className="2xl:text-3xl mb-4">1. LLAMA3.1: Meta Open Sourcing a SOTA 401B Model</h3>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    A pivotal moment came when Meta open-sourced Llama3.1, a 
                                    state-of-the-art (SOTA) 400 billion parameter model, giving 
                                    companies a difficult choice: invest upwards of $100 million in 
                                    building and training their own large-scale models, or fine-tune 
                                    Meta's freely available model that showcased comparable 
                                    performance to GPT's best model. Most companies opted for the 
                                    latter, seeing the immense cost savings and shorter time to 
                                    market.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mt-8">
                                    This led to a sharp decline in the demand for training resources. 
                                    With the availability of Meta's model, it became clear that many 
                                    organizations no longer needed to invest in expensive GPU time 
                                    for training from scratch. Instead, fine-tuning existing models, 
                                    which requires significantly less compute power, became the more 
                                    attractive and cost-effective route.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <img
                                    src={Picture3}
                                    alt="Meta's LLAMA3.1 Impact"
                                    className="w-full rounded-lg mb-8"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Llama3.1 405B outperformed GPT-4o on multiple benchmark test during release</div>
                                    <div>Source: @AIatMeta</div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="bg-gray-800 rounded-lg py-1 px-4">
                                    <h4 className="2xl:text-3xl mb-4 text-center">Definition of Fine-Tuning</h4>
                                    <p className="2xl:text-2xl 2xl:leading-[2.2rem]">
                                        Fine-tuning is the process of taking a pre-trained machine learning model and adapting it to a specific task by 
                                        training it further on a smaller, task-specific dataset. It allows the model to leverage its existing knowledge 
                                        while adjusting to new patterns in the target data, making it more effective for the specific application.
                                    </p>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h3 className="2xl:text-3xl mb-4">2. Increased Supply of H100s</h3>
                        <div className="grid lg:grid-cols-1 gap-8 mt-12">
                            {/* Left Column */}
                            <div>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    At the same time, the supply of H100s dramatically increased. 
                                    Several key factors contributed to this, including better production 
                                    yields and the fulfillment of large backlogged orders from 2023. 
                                    This increased availability of GPUs made it easier for companies to 
                                    acquire allocations, particularly as former mining firms pivoted 
                                    into the AI space. These companies, now dubbed "Neoclouds," 
                                    capitalized on the oversupply by entering the market with large 
                                    H100 clusters just as training demand began to wane. Many of 
                                    these Neoclouds focused exclusively on H100 SXMs, further adding 
                                    to the supply glut.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <img
                                    src={Picture4}
                                    alt="H100 Supply Increase"
                                    className="w-[60%] rounded-lg"
                                />
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h3 className="2xl:text-3xl mb-4">3. Shift to Inference and Cheaper Alternatives</h3>
                        <div className="grid lg:grid-cols-1 gap-8 mt-12">
                            {/* Left Column */}
                            <div>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    As demand for large-scale training declined, a significant portion of 
                                    AI activity shifted toward inference, where trained models are 
                                    used to make predictions and perform tasks in real time. Inference 
                                    workloads require less GPU power compared to training, and 
                                    cheaper alternatives to the H100—such as Nvidia's L40 GPUs and 
                                    specialized inference hardware from companies like Groq, 
                                    Cerebras, and SambaNova—became the go-to solutions.
                                </p>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mt-8">
                                    These inference-specialized chips offer lower cost per compute for 
                                    inference tasks, further eroding the demand for H100 GPUs. The 
                                    market for GPUs was increasingly saturated, and with fewer 
                                    organizations requiring the expensive H100s for training, prices 
                                    began to tumble.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <img
                                    src={Picture5}
                                    alt="Inference Hardware Solutions"
                                    className="w-[60%] rounded-lg"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Sources: Groq and Cerebras</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-2">Future of H100 Pricing</h2>
                        <h3 className="2xl:text-3xl mb-6">Looking ahead</h3>
                        <div className="">
                            
                            <ul className="space-y-6">
                                <li className="flex items-start">
                                    <span className="text-blue-400 mr-3 mt-2">•</span>
                                    <p className="2xl:text-2xl 2xl:leading-[2.2rem]">
                                        <strong>AMD and Blackwell Competition:</strong> AMD's push into AI and Nvidia's upcoming Blackwell GPUs will increase 
                                        competition. Blackwell is expected to outperform and underprice the H100, likely driving down H100 prices as 
                                        customers upgrade.
                                    </p>
                                </li>
                                <li className="flex items-start">
                                    <span className="text-blue-400 mr-3 mt-2">•</span>
                                    <p className="2xl:text-2xl 2xl:leading-[2.2rem]">
                                        <strong>Inference Hardware:</strong> The rise of inference-specialized chips is reducing demand for H100s, as companies 
                                        prefer more affordable, inference-optimized hardware.
                                    </p>
                                </li>
                                <li className="flex items-start">
                                    <span className="text-blue-400 mr-3 mt-2">•</span>
                                    <p className="2xl:text-2xl 2xl:leading-[2.2rem]">
                                        <strong>Oversupply and Price Drops:</strong> An oversupply of H100s, combined with competition and new alternatives, will 
                                        likely push rental prices below $2 per hour, especially as Neoclouds expand.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchCrash;
