import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../App';
import { motion, AnimatePresence } from "framer-motion";

function Navbar() {
  const { isAuthenticated, handleLogout, isSuperUser } = useContext(AuthContext);
  const [sticky, setSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [consultingTitles, setConsultingTitles] = useState([]);
  const [consultingIcons, setConsultingIcons] = useState({});
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Icons mapping for consulting services
  const serviceIcons = {
    "Production Ready Inference": "🚀",
    "Cost of Ownership Model": "💰",
    "Location Services Model": "🌎",
    "Model Tracking and Evolution": "📈",
    "HPC Hardware Tracking": "🖥️"
  };

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Close menu when location changes
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    fetch("/assets/consulting.json")
      .then((response) => response.json())
      .then((data) => {
        setConsultingTitles(data.map((item) => item.title));
        
        // Create icons mapping based on titles
        const icons = {};
        data.forEach(item => {
          // Find matching icon or use default
          const iconKey = Object.keys(serviceIcons).find(key => 
            item.title.toLowerCase().includes(key.toLowerCase())
          );
          icons[item.title] = iconKey ? serviceIcons[iconKey] : "✨";
        });
        setConsultingIcons(icons);
      })
      .catch((error) => console.error("Error fetching consulting titles:", error));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleOurTeamClick = () => {
    // Close the mobile menu first
    setMenuOpen(false);
    
    if (location.pathname === "/") {
      const ourTeamSection = document.getElementById("our-team-section");
      if (ourTeamSection) {
        ourTeamSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { replace: false });
      setTimeout(() => {
        const ourTeamSection = document.getElementById("our-team-section");
        if (ourTeamSection) {
          ourTeamSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  };

  const handleAddPost = () => {
    setMenuOpen(false);
    navigate("/admin/addpost");
  };

  const handleNavigate = (path) => {
    setMenuOpen(false);
    navigate(path);
  };

  const navItems = [
    { name: "AI Marketplace", externalLink: "https://fp8.io/" },
    { name: "Consulting", href: "/consulting" },
    { name: "Market Education", href: "/research-overviews" },
    { name: "Models", href: "/models" },
    { name: "Our Team", action: handleOurTeamClick },
  ];

  const renderAuthButtons = () => {
    if (isAuthenticated) {
      return (
        <div className="flex items-center space-x-4">
          {isSuperUser && (
            <button
              onClick={handleAddPost}
              className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
            >
              Add Post
            </button>
          )}
          <button
            onClick={() => {
              setMenuOpen(false);
              handleLogout();
            }}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
          >
            Logout
          </button>
        </div>
      );
    } else {
      // Commented out login/signup buttons
      /* 
      return (
        <div className="flex items-center space-x-4">
          <button
            onClick={() => {
              setMenuOpen(false);
              navigate("/login");
            }}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
          >
            Login
          </button>
          <button
            onClick={() => {
              setMenuOpen(false);
              navigate("/signup");
            }}
            className="px-4 py-2 bg-[#2887BF] text-white rounded-lg hover:bg-blue-900 transition duration-300"
          >
            Sign Up
          </button>
        </div>
      );
      */
      return null;
    }
  };

  return (
    <div
      className={`max-w-full fixed top-0 left-0 right-0 z-50 ${
        sticky ? "shadow-md bg-[#0D203B] backdrop-blur-md bg-opacity-95" : "bg-transparent backdrop-blur-sm bg-opacity-80"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between py-4 px-4 md:px-10 lg:px-20 text-white">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setMenuOpen(false);
            navigate("/");
          }}
        >
          <span className="text-4xl 2xl:text-6xl font-bold px-2">FPX</span>
        </div>

        <div className="hidden lg:flex lg:space-x-6 2xl:text-3xl">
          {navItems.map((item) =>
            item.externalLink ? (
              <a
                key={item.name}
                href={item.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded hover:bg-[#112455] transition duration-200 text-white no-underline relative after:content-[''] after:absolute after:w-0 after:h-0.5 after:bg-blue-400 after:left-0 after:bottom-0 after:transition-all hover:after:w-full"
              >
                {item.name}
              </a>
            ) : item.name === "Our Team" ? (
              <button
                key={item.name}
                onClick={item.action}
                className="p-2 rounded hover:bg-[#112455] transition duration-200 relative after:content-[''] after:absolute after:w-0 after:h-0.5 after:bg-blue-400 after:left-0 after:bottom-0 after:transition-all hover:after:w-full"
              >
                {item.name}
              </button>
            ) : item.name === "Consulting" ? (
              <div 
                key={item.name} 
                className="relative"
                ref={dropdownRef}
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <button
                  onClick={() => handleNavigate("/consulting")}
                  className="p-2 rounded hover:bg-[#112455] transition duration-200 flex items-center relative after:content-[''] after:absolute after:w-0 after:h-0.5 after:bg-blue-400 after:left-0 after:bottom-0 after:transition-all hover:after:w-full"
                >
                  {item.name}
                  <svg 
                    className={`ml-1 w-4 h-4 transition-transform duration-300 ${dropdownOpen ? "rotate-180" : ""}`} 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                
                <AnimatePresence>
                  {dropdownOpen && (
                    <motion.div 
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 top-12 w-72 bg-gradient-to-b from-[#112455] to-[#0D203B] rounded-lg shadow-lg overflow-hidden z-50 border border-blue-900"
                    >
                      <div className="p-2">
                        {consultingTitles.map((title, index) => (
                          <motion.a
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavigate(`/consulting`);
                              // Use setTimeout to ensure the page loads before trying to access elements
                              setTimeout(() => {
                                // Find the button with this title and click it
                                const buttons = document.querySelectorAll('.bg-blue-900\\/30 button');
                                buttons.forEach(button => {
                                  if (button.textContent.trim() === title) {
                                    button.click();
                                  }
                                });
                              }, 300);
                            }}
                            className="flex items-center p-3 mb-1 rounded-md hover:bg-blue-800/30 text-white no-underline transition-all group cursor-pointer"
                            whileHover={{ x: 5 }}
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.05 }}
                          >
                            <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 mr-3 rounded-full bg-blue-800/50 group-hover:bg-blue-700 transition-colors">
                              {consultingIcons[title] || "✨"}
                            </span>
                            <span className="text-sm font-medium">{title}</span>
                          </motion.a>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              <a
                key={item.name}
                onClick={() => handleNavigate(item.href)}
                className="p-2 rounded hover:bg-[#112455] transition duration-200 text-white no-underline cursor-pointer relative after:content-[''] after:absolute after:w-0 after:h-0.5 after:bg-blue-400 after:left-0 after:bottom-0 after:transition-all hover:after:w-full"
              >
                {item.name}
              </a>
            )
          )}
          {renderAuthButtons()}
        </div>

        <div className="lg:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-4 6h-8"
              />
            </svg>
          </button>
        </div>
      </div>

      <AnimatePresence>
        {menuOpen && (
          <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="lg:hidden overflow-hidden bg-[#0D203B]/95 backdrop-blur-md"
          >
            <div className="flex flex-col space-y-2 p-4">
              {navItems.map((item, index) =>
                item.externalLink ? (
                  <motion.a
                    key={item.name}
                    href={item.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-3 rounded-lg bg-[#112455]/50 hover:bg-[#112455] transition duration-200 text-white no-underline"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.05 }}
                    onClick={() => setMenuOpen(false)}
                  >
                    {item.name}
                  </motion.a>
                ) : item.name === "Our Team" ? (
                  <motion.button
                    key={item.name}
                    onClick={item.action}
                    className="p-3 rounded-lg bg-[#112455]/50 hover:bg-[#112455] transition duration-200 text-white no-underline text-left"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.05 }}
                  >
                    {item.name}
                  </motion.button>
                ) : item.name === "Consulting" ? (
                  <div key={item.name}>
                    <motion.button
                      onClick={() => handleNavigate("/consulting")}
                      className="w-full p-3 rounded-lg bg-[#112455]/50 hover:bg-[#112455] transition duration-200 text-white no-underline text-left"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.05 }}
                    >
                      {item.name}
                    </motion.button>
                  </div>
                ) : (
                  <motion.a
                    key={item.name}
                    onClick={() => handleNavigate(item.href)}
                    className="p-3 rounded-lg bg-[#112455]/50 hover:bg-[#112455] transition duration-200 text-white no-underline cursor-pointer"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.05 }}
                  >
                    {item.name}
                  </motion.a>
                )
              )}
              <div className="pt-2">
                {renderAuthButtons()}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Navbar;