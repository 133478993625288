import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/art/Picture1.png'
import Picture2 from '../assets/art/Picture2.png'
import Picture3 from '../assets/art/Picture3.png'
import Picture4 from '../assets/art/Picture4.png'
import Picture5 from '../assets/art/Picture5.png'
import Picture6 from '../assets/art/Picture6.png'
import Picture7 from '../assets/art/Picture7.png'


const InsideResearchArt = () => {
    const tags = ["Large Language Model", "Prompt", "ChatGPT", "Claude", "Perplexity", "Prompt Engineering"]
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">
                        Art of Prompt Engineering
                    </h2>
                    
                    <p className="2xl:text-3xl mb-4">
                        <strong>Description:</strong> This report explores advanced prompt engineering techniques, 
                        providing tailored strategies to maximize AI language model capabilities in varied contexts.
                    </p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">
                        Posted on: 10/21/2024
                    </div>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Abstract</h2>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Text Content */}
                            <div>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Artificial Intelligence (AI) language models have 
                                    reshaped our ability to interact with machines, 
                                    enabling complex tasks to be automated. To 
                                    unlock AI's full potential, users must understand 
                                    how to effectively communicate with these 
                                    models through prompt engineering. This report 
                                    focuses on delivering unique and powerful 
                                    prompt strategies that go beyond basic tips, 
                                    tailoring them to the specifics of different model 
                                    capabilities—such as access to real-time 
                                    information, knowledge cutoffs, and the ability to 
                                    integrate external data.
                                </p>
                            </div>

                            {/* Image */}
                            <div>
                                <img
                                    src={Picture1}
                                    alt="Prompt Engineering Concept"
                                    className="w-[50%] h-[90%] rounded-lg"
                                />
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h3 className="2xl:text-3xl mb-6">Why is Prompt Engineering needed?</h3>

                        {/* Definition */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-4">Definition</h4>
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                Prompt engineering refers to the art of crafting inputs or questions to optimize an AI model's responses.
                            </p>
                        </div>

                        {/* Why is prompt engineering needed? */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-4">Why is prompt engineering needed?</h4>
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                AI models generate responses by predicting patterns from past data, not by truly understanding language. This makes them 
                                prone to errors when prompts are unclear, lack context, exceed token limits, or reference events beyond their knowledge 
                                cutoff. Prompt engineering is essential to guide the model, ensuring it has the clarity and context needed to generate 
                                accurate and relevant results despite these limitations.
                            </p>
                        </div>

                        {/* What is a prompt? */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-4">What is a prompt?</h4>
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                A prompt is an input or question given to an AI model to generate a specific response.
                            </p>
                        </div>

                        {/* How is it "engineering"? */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-4">How is it "engineering"?</h4>
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                It's considered "engineering" because it requires iterative trial and error, refining prompts to achieve precise, 
                                effective outcomes.
                            </p>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h3 className="2xl:text-3xl mb-6">Essential Model Characteristics That Impact Prompting</h3>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Before diving into advanced prompt engineering techniques, it's crucial to understand the model-specific characteristics that 
                            directly impact how you should approach crafting prompts. These include the knowledge cutoff, context window size, and 
                            whether the model has internet access.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Knowledge Cutoff and Internet Access</h4>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                                    <li className="mb-2">• Perplexity: Offers real-time internet access with no 
                                        knowledge cutoff, making it ideal for querying current 
                                        events.</li>
                                    <li className="mb-2">• Claude Sonnet 3.5: Has a knowledge cutoff in April 
                                        2024 but supports document uploads and tool pairing to 
                                        allow the user to provide the relevant context.</li>
                                    <li className="mb-2">• GPT-4o with Internet Access: Despite a knowledge 
                                        cutoff of October 2023, it fetches real-time data from 
                                        the web, blending old knowledge with up-to-date 
                                        information.</li>
                                </ul>

                                {/* Pro Tip Container */}
                                <div className="bg-gray-800 rounded-lg p-6 mb-8">
                                    <h5 className="2xl:text-3xl font-semibold text-center mb-4">Pro Tip</h5>
                                    <p className="2xl:text-2xl text-center">
                                        To maximize the effectiveness of your prompts, prioritize 
                                        clarity and relevance. Only include the essential parts of 
                                        your query to stay within the token limit and maintain 
                                        coherence.
                                    </p>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Context Window Size</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                                    The context window defines how much information a 
                                    model can handle in one conversation, often measured in 
                                    tokens (a fundamental unit of data processed by an LLM). 
                                    While large models can process thousands of tokens, 
                                    exceeding this limit results in information loss, which can 
                                    cause the model to miss important details.
                                </p>

                                {/* Table */}
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr>
                                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-bold border border-blue-800">
                                                    Model
                                                </th>
                                                <th className="py-3 px-4 bg-blue-900 text-white text-left font-bold border border-blue-800">
                                                    Context Window Size
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">GPT-4o</td>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Up to 128,000 tokens</td>
                                            </tr>
                                            <tr>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Llama 3.1</td>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Up to 32,000 tokens</td>
                                            </tr>
                                            <tr>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Claude Sonnet 3.5</td>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Up to 200,000 tokens</td>
                                            </tr>
                                            <tr>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Gemini 1.5 Pro</td>
                                                <td className="py-3 px-4 bg-blue-800 text-white border border-blue-700">Up to 2,000,000 tokens</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Advanced Prompt Engineering Techniques</h2>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Now that we've addressed how model limitations can affect interactions, let's explore advanced prompt engineering 
                            techniques tailored to these constraints.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Real-Time Information Access (e.g., Perplexity)
                                </h3>
                                
                                <img
                                    src={Picture2}
                                    alt="Real-Time Information Access"
                                    className="w-[15%] h-[10%] rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: With models like Perplexity, you can 
                                    design prompts that require time-sensitive or 
                                    current information. For instance, instead of asking 
                                    general questions like "What is inflation?" you can 
                                    request more actionable insights:
                                </p>

                                <img
                                    src={Picture4}
                                    alt="Real-Time Data Example"
                                    className="w-full rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Data-Driven Prompts: You can ask Perplexity to 
                                    retrieve specific reports or even evaluate trends 
                                    from live datasets, which traditional models 
                                    without real-time access cannot do.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Cutoff Models with Document Integration
                                </h3>

                                <img
                                    src={Picture3}
                                    alt="Document Integration"
                                    className="w-[30%] h-[10%] rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: When using a model like GPT-4o that 
                                    supports document uploads, you can instruct it to cross-
                                    reference new data with the provided documents. For 
                                    example:
                                </p>

                                <img
                                    src={Picture5}
                                    alt="Document Integration Example"
                                    className="w-full rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    By layering real-time data or additional resources with 
                                    the model's core knowledge, you can extract much more 
                                    nuanced responses.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Advanced Prompt Engineering Techniques</h2>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Step-by-Step and Role-Specific Prompts
                                </h3>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    One of the most effective strategies for improving 
                                    the accuracy of AI-generated responses is to guide 
                                    the model through a step-by-step process.
                                </p>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: Rather than asking for a simple 
                                    explanation, instruct the model to assume a specific 
                                    role and tackle the problem in stages:
                                </p>

                                <img
                                    src={Picture6}
                                    alt="Step-by-Step Prompting Example"
                                    className="w-full rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    This technique leads to more comprehensive, multi-
                                    step answers, breaking complex topics into 
                                    manageable parts while maintaining the depth of 
                                    information.
                                </p>
                            </div>

                            {/* Right Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Prompt Chaining for Complex Outputs
                                </h3>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Prompt chaining involves creating a series of related 
                                    prompts where each response builds on the previous 
                                    one. This technique helps handle complex queries that 
                                    exceed the model's context window or require more 
                                    detailed reasoning.
                                </p>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: Start with a high-level request, then narrow 
                                    down each subsequent prompt to achieve a richer and 
                                    more structured final result:
                                </p>

                                {/* Three gray containers */}
                                <div className="space-y-4 mb-">
                                    <div className="bg-gray-800 rounded-lg px-4 py-1">
                                        <p className="2xl:text-2xl">
                                            First prompt: Provide a general overview of the AI hardware landscape in 
                                            2024
                                        </p>
                                    </div>
                                    
                                    <div className="bg-gray-800 rounded-lg px-4 py-1">
                                        <p className="2xl:text-2xl">
                                            Follow-up prompt: Now focus on the demand for high-performance GPUs in 
                                            AI training and compare it with the demand for inference workloads.
                                        </p>
                                    </div>
                                    
                                    <div className="bg-gray-800 rounded-lg px-4 py-1">
                                        <p className="2xl:text-2xl">
                                            Final prompt: "Which AI companies have shifted their hardware strategies 
                                            due to the increased demand for inference? List them and explain the 
                                            rationale behind their decisions.
                                        </p>
                                    </div>
                                </div>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    By refining each query, you can guide the model through 
                                    a complex set of ideas and ensure a higher level of 
                                    precision in the final output.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Avoiding Common Pitfalls and Maximizing Performance</h2>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Now that we've touched on the common pitfalls with AI models, let's dive into strategies for handling hallucinations 
                            and optimizing prompt performance.
                        </p>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Left Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Handling Model Hallucinations
                                </h3>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    AI models sometimes generate hallucinations—
                                    answers that are incorrect but sound plausible. To 
                                    mitigate this, prompt the model to signal 
                                    uncertainty where appropriate.
                                </p>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: Directly instruct the model to 
                                    acknowledge if it is unsure of any facts. This reduces 
                                    overconfidence in its responses and provides a 
                                    clearer idea of the reliability of the information: "If 
                                    you're unsure about any part of your answer, 
                                    indicate this and explain why."
                                </p>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Alternatively, providing the model with more 
                                    context through uploaded documents or by utilizing 
                                    a vector database can help significantly reduce 
                                    hallucination.
                                </p>

                                {/* Vector Database Container */}
                                <div className="bg-gray-800 rounded-lg p-6">
                                    <h4 className="2xl:text-3xl font-semibold text-center mb-4">Vector Database</h4>
                                    <p className="2xl:text-2xl text-center">
                                        A vector database organizes data in a way that allows it 
                                        to find similar items quickly, making it useful for tasks 
                                        like searching related content or improving 
                                        recommendation systems.
                                    </p>
                                </div>
                            </div>

                            {/* Right Column */}
                            <div>
                                <h3 className="2xl:text-3xl font-semibold mb-6">
                                    Maximizing Context Window Efficiency
                                </h3>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    To avoid losing important information due to context 
                                    window limits, be strategic about how you organize your 
                                    prompts.
                                </p>

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-6">
                                    Unique Tip: Use bullet points or numbered lists to break 
                                    down complex queries. This structure is not only easier 
                                    for the model to process but also ensures key information 
                                    remains intact:
                                </p>

                                <img
                                    src={Picture7}
                                    alt="Context Window Efficiency Example"
                                    className="w-full rounded-lg mb-6"
                                />

                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    This ensures that the most critical data is retained within 
                                    the model's token limit, maximizing its ability to generate 
                                    complete responses.
                                </p>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchArt;
