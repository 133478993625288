import React from 'react';
import Footer from '../Footer';
import Picture1 from '../assets/agentic/Picture1.png'
import Picture2 from '../assets/agentic/Picture2.jpg'
import Picture3 from '../assets/agentic/Picture3.jpg'
import Picture4 from '../assets/agentic/Picture4.jpg'
import Picture5 from '../assets/agentic/Picture5.png'
import Picture6 from '../assets/agentic/Picture6.jpg'
import Picture7 from '../assets/agentic/Picture7.png'
import Picture8 from '../assets/agentic/Picture8.jpg'

const InsideResearchAgentic = () => {
    const tags = ["Agents", "AI Agents", "LLM", "Enterprise Value", "Vector Databases"]
    
    return (
        <div>
            <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] flex flex-col items-center py-20 text-white min-h-screen">
                <div className="max-w-[95%] lg:max-w-[70%] 2xl:max-w-[70%]" style={{
                    fontFamily: 'system-ui, -apple-system, sans-serif',
                    lineHeight: '1.6',
                    margin: '0 auto',
                    padding: '20px',
                    color: '#fff',
                }}>
                    <h2 className="mb-4 text-center font-bold 2xl:text-5xl 2xl:mt-10">The Age of Agentic AI: Optimizing Enterprise Value
                    through Autonomous Agents</h2>
                    <p className="2xl:text-3xl mb-4"><strong>Description:</strong> This report educates the reader on how you can optimize your Enterprise Value using AI Agents. It aims to educate readers on how agentic systems work and the various ways it can add value to your organization.</p>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
                        <ul className="flex flex-wrap justify-center gap-2">
                            {tags.map((tag, index) => (
                                <li
                                    className="bg-blue-900/70 px-3 py-1 text-[0.9rem] 2xl:text-2xl uppercase tracking-wider text-white rounded-full dark:text-white/70"
                                    key={index}
                                >
                                    {tag}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex justify-between items-center w-full max-w-3xl lg:max-w-6xl mb-10 2xl:text-3xl">Posted on: 12/4/2024</div>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl">Abstract</h2>
                        <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                            As AI becomes more accessible, a new frontier is rapidly gaining traction: AI agents. These
                            autonomous systems are reshaping what's possible by setting and achieving goals, dynamically
                            adapting to their environment, and collaborating across systems—all without human oversight.
                            While Gartner predicts that by 2028, 33% of enterprise software applications will leverage agentic
                            AI, we anticipate that this figure will be far higher as organizations recognize the immense potential
                            of these systems. Companies that act now to develop robust agentic capabilities will gain a powerful
                            advantage, using these technologies to transform automation, streamline operations, and redefine
                            customer experience at an unprecedented scale.
                        </p>

                        <div className="2xl:text-3xl 2xl:leading-[2.5rem] px-5">
                            <img
                                src={Picture1}
                                alt="AI Agents concept visualization"
                                style={{ maxWidth: '100%', height: 'auto', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
                            />
                            <div className="2xl:text-2xl text-gray-300 italic">
                                <div>Figure: AI Agent workflow </div>
                                <div>Source: rightinformation.com</div>
                            </div>
                        </div>
                    </section>
                    
                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Understanding Agentic AI</h2>
                        <h3 className="2xl:text-3xl mb-6">Definition and Application</h3>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* First Column */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">What are AI agents?</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Agentic AI refers to autonomous software 
                                    entities—agents—that perceive their 
                                    environment, make decisions, and perform 
                                    actions to achieve specific goals without 
                                    continuous human guidance. These agents 
                                    utilize LLMs under the hood, streamline 
                                    processes, and adapt to new circumstances. 
                                    Unlike traditional AI models that process tasks 
                                    in isolation, agentic AI possesses a "chaining" 
                                    ability, enabling it to respond to a single 
                                    request with a series of coordinated actions. 
                                    This allows complex goals to be broken down 
                                    into smaller, manageable steps, guiding users 
                                    through every stage of a project.
                                </p>
                            </div>

                            {/* Second Column */}
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">How Does Agentic AI Differ from Traditional AI Models?</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Traditional AI models typically require explicit 
                                    instructions and human oversight for each task. 
                                    They process tasks in isolation and lack the ability 
                                    to autonomously plan multi-step actions. Agentic 
                                    AI, on the other hand, operates proactively. It can 
                                    generate comprehensive action plans, adapt to 
                                    changing situations, and learn from experiences 
                                    without human intervention. For example, when 
                                    tasked with creating a website, an agentic AI 
                                    system would independently set objectives like 
                                    selecting a content management system, drafting 
                                    content, designing the layout, and optimizing for 
                                    search engines, guiding the user from concept to 
                                    completion.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Understanding Agentic AI</h2>
                        <h3 className="2xl:text-3xl mb-6">Levels of Autonomy</h3>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Defining AI agents has been challenging due to their varied capabilities. On this slide, we show a categorization 
                            framework to clarify agents' roles in automating knowledge work and how they will evolve with further advancements in 
                            agentic systems.
                        </p>

                        <div className="flex flex-col gap-4">
                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 5: Autonomy (AGI)</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Hypothetical agents capable of end-to-end knowledge work with no oversight, using creativity and logical reasoning to handle complex, 
                                    unfamiliar tasks.<br/>
                                    <strong>Example:</strong> Fully autonomous digital knowledge worker, equivalent to an experienced human employee.
                                </p>
                            </div>

                            <div className="bg-gray-900 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 4: Self-Refinement</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Advanced agents that can improve performance autonomously, update their processes, connect to new data, and self-optimize.<br/>
                                    <strong>Example:</strong> Adaptive invoice-processing agent that adds new vendors with minimal oversight and improves accuracy over time.
                                </p>
                            </div>

                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 3: Plan and Reflect</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    These agents exhibit constrained autonomy, adjusting plans based on user goals and refining their approach in real time.<br/>
                                    <strong>Example:</strong> AI agent reconciling invoices, capable of adapting to complex rules and data variability.
                                </p>
                            </div>

                            <div className="bg-gray-900 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 2: Agentic Assistant</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Task-specific agents with the ability to interpret user intent and call tools but limited to single tasks with short-term plans.<br/>
                                    <strong>Example:</strong> Virtual assistant summarizing or drafting emails based on prompts.
                                </p>
                            </div>

                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 1: AI-Augmented Automation</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Basic decision-making augmented by LLMs, introducing limited agentic capabilities in static, rule-based workflows.<br/>
                                    <strong>Example:</strong> Sorting customer support emails and forwarding them to the correct teams.
                                </p>
                            </div>

                            <div className="bg-gray-900 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-2">Level 0: Fixed Automation</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Fixed rule-based automation (e.g., RPA) lacks agentic behavior, following deterministic rules without adaptation or planning.<br/>
                                    <strong>Example:</strong> Data entry tasks, form-filling, and simple web scraping
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">How Does an Agentic System Work?</h2>
                        <h3 className="2xl:text-3xl mb-6">A Simplified View</h3>

                        <div className="flex flex-col gap-6">
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                • It is worthwhile to discuss how these agents operate. As previously mentioned, agents 
                                utilize Language Models (LMs) under the hood. Calls to the LMs can be made using API calls 
                                from off-the-shelf services like OpenAI or Anthropic APIs, or from models deployed in-house, 
                                but the essence remains the same.
                            </p>

                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                • The tasks we design rely heavily on prompts; you need to tell the models exactly what to do. 
                                Here, prompts play an important role in determining how the model behaves and interacts with 
                                other agents to synchronize and harmonize to achieve common (or perhaps distinct) goals.
                            </p>

                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                • Each agent is an independent computational program with its own set of inputs and outputs. 
                                The inputs, like in any other Python program, can be any relevant data, and the outputs can be 
                                used to deliver your desired action.
                            </p>

                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                • An important part of dealing with agentic systems is orchestrating how the agents interact with 
                                each other. Many toolkits have been developed since the emergence of agentic system 
                                development, including LangChain, LangGraph, AutoGPT, and BabyAGI. These frameworks 
                                differ in their approaches and capabilities. Despite these developments there is a clear of 
                                standardization as many of these libraries remain experimental in nature. While these 
                                frameworks and libraries are topics for a future report, feel free to reach out to us if you have 
                                any questions. We at BitOoda specialize in the use of many of these frameworks.
                            </p>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Benefits of Agentic AI in Enterprises</h2>
                        <h3 className="2xl:text-3xl mb-6">Characteristics of Agents and How They Can be Beneficial</h3>

                        <div className="grid lg:grid-cols-1 gap-6">
                            {/* First Card */}
                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-4">Improved Complex Task Automation</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Agentic AI automates complex tasks 
                                    by breaking them into smaller steps, 
                                    relieving human teams to focus on 
                                    strategic work, enhancing 
                                    operational efficiency, and ensuring 
                                    consistent, high-quality outcomes.
                                </p>
                            </div>

                            {/* Second Card */}
                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-4">Flexibility in Changing Situations</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Agentic AI's adaptability allows it to 
                                    adjust in real-time to market shifts 
                                    or feedback, staying relevant 
                                    without extensive oversight and 
                                    enabling quick enterprise responses 
                                    to new developments.
                                </p>
                            </div>

                            {/* Third Card */}
                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-4">Enhanced Collaboration</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Agentic AI fosters teamwork by 
                                    coordinating tasks, identifying team 
                                    strengths, and addressing 
                                    collaboration challenges, creating a 
                                    unified platform for knowledge 
                                    sharing and efficient resource use.
                                </p>
                            </div>

                            {/* Fourth Card */}
                            <div className="bg-gray-800 p-6 rounded-lg">
                                <h4 className="2xl:text-3xl font-semibold mb-4">Scalability for Future Growth</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    The modular design of agentic AI 
                                    supports easy scalability, allowing 
                                    enterprises to add features or 
                                    manage growth without major 
                                    redesigns, supporting business 
                                    expansion seamlessly.
                                </p>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Enterprise Applications of Agentic AI</h2>
                        <h3 className="2xl:text-3xl mb-6">Early Applications in Business</h3>

                        <p className="2xl:text-3xl 2xl:leading-[2.5rem] mb-8">
                            Let's dive into the simplest application of Agentic RAGs. But first, it's essential to understand what RAGs are.
                        </p>

                        {/* RAG Explanation Grid */}
                        <div className="grid lg:grid-cols-1 gap-8 mb-8">
                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">What is RAG?</h4>
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    Retrieval-Augmented Generation (RAG) 
                                    combines generative AI with real-time data 
                                    retrieval, creating responses grounded in actual 
                                    information from a knowledge base.
                                </p>
                            </div>

                            <div>
                                <h4 className="2xl:text-3xl font-semibold mb-4">Why is RAG Needed?</h4>
                                <ul className="2xl:text-3xl 2xl:leading-[2.5rem] list-decimal pl-6">
                                    <li>Overcomes Knowledge Cutoff: RAG keeps 
                                    responses current by accessing recent data.</li>
                                    <li>Reduces Hallucination: Real info retrieval 
                                    lowers the chance of AI making stuff up.</li>
                                    <li>Boosts Enterprise Data Value: RAG taps into 
                                    company data, making it actionable and 
                                    valuable.</li>
                                </ul>
                            </div>
                        </div>

                        {/* First Image */}
                        <figure className="mb-8">
                            <img
                                src={Picture2}
                                alt="RAG Visualization"
                                className="w-1/2 rounded-lg"
                            />
                            <div className="2xl:text-2xl text-gray-300 italic">
                                <div>Source: www.it-daily.net</div>
                            </div>
                        </figure>

                        {/* Agentic RAGs Explanation */}
                        <div className="mb-8">
                            <h4 className="2xl:text-3xl font-semibold mb-4">What are Agentic RAGs</h4>
                            <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                Agentic RAG represents a significant advancement over 
                                traditional Retrieval-Augmented Generation models. While 
                                traditional RAG systems follow a linear pipeline—response 
                                generation, synthesis, reclassification, and extraction—
                                agentic RAG employs agent-based technologies to provide 
                                coordinated responses across multiple documents. This 
                                approach not only accesses information but also 
                                understands the context and relevance of the data being 
                                retrieved, offering more comprehensive and accurate 
                                answers.
                            </p>
                        </div>

                        {/* Second Image */}
                        <figure>
                            <img
                                src={Picture3}
                                alt="Agentic RAG Visualization"
                                className="w-1/2 h-1/2 rounded-lg"
                            />
                            <div className="2xl:text-2xl text-gray-300 italic">
                                <div>Source: www.it-daily.net</div>
                            </div>
                        </figure>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">Enterprise Applications of Agentic AI</h2>
                        <h3 className="2xl:text-3xl mb-6">More Industry Applications</h3>

                        <div className="grid lg:grid-cols-1 xl:grid-cols-1 gap-6">
                            {/* ServiceNow Card */}
                            <div className="">
                                <img
                                    src={Picture4}
                                    alt="ServiceNow Application"
                                    className="object-cover"
                                />
                                <div className="py-6">
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        Cloud software provider ServiceNow is 
                                        integrating AI agents into its platform to 
                                        automate IT and customer service management. 
                                        The AI handles routine ticket requests 
                                        autonomously, reaching out to human 
                                        supervisors only when necessary. This shift 
                                        allows IT professionals to focus on more strategic 
                                        tasks, enhancing productivity and operational 
                                        efficiency.
                                    </p>
                                </div>
                            </div>

                            {/* Palantir Card */}
                            <div className="">
                                <img
                                    src={Picture5}
                                    alt="Palantir Application"
                                    className="object-cover"
                                />
                                <div className="py-6">
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        Data analytics company Palantir uses agentic AI 
                                        internally for legal work, human resources, and facilities 
                                        management. It also assists clients in developing their 
                                        own AI agents. The company highlights potential 
                                        challenges such as the need for governance and the risks 
                                        of AI-driven cyberattacks but underscores the benefits of 
                                        a "virtual workforce" that augments human efforts.
                                    </p>
                                </div>
                            </div>

                            {/* Intuit Card */}
                            <div className="">
                                <img
                                    src={Picture6}
                                    alt="Intuit Application"
                                    className="object-cover"
                                />
                                <div className="py-6">
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        Intuit is leveraging agentic AI to navigate tax 
                                        code changes that impact its products. Instead of 
                                        a team of developers researching and 
                                        implementing new elements, agentic AI spans 
                                        various functions—from detecting changes to 
                                        associating them with code and determining 
                                        necessary modifications—acting as a copilot for 
                                        developers.
                                    </p>
                                </div>
                            </div>

                            {/* Accenture Card */}
                            <div className="">
                                <img
                                    src={Picture7}
                                    alt="Accenture Application"
                                    className=" object-cover"
                                />
                                <div className="py-6">
                                    <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                        Accenture has partnered with NVIDIA to lead 
                                        enterprises into the era of AI by launching the 
                                        Accenture AI Refinery platform. This platform 
                                        assists companies in initiating their custom 
                                        agentic AI journeys using NVIDIA's AI stack. 
                                        Internally, Accenture's marketing function is 
                                        integrating the AI Refinery platform with 
                                        autonomous agents to create and run smarter 
                                        campaigns more efficiently
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section style={{ marginBottom: '40px' }}>
                        <h2 className="2xl:text-4xl mb-8">BitOoda's Agentic AI Development</h2>
                        <h3 className="2xl:text-3xl mb-6">Agent Products We Develop & Deploy</h3>

                        <div className="grid lg:grid-cols-1 gap-8">
                            {/* Text Content */}
                            <div className="flex flex-col gap-6">
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    • BitOoda builds custom agentic systems, 
                                    applying AI expertise to create tailored 
                                    platforms for business needs in AI, crypto, and 
                                    finance.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    • These tools drive productivity, streamline tasks, 
                                    and improve decision-making, enabling 
                                    businesses to operate more efficiently, gain 
                                    insights, and achieve scalable growth.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    • BitOoda's in-house AI-powered brainstorming 
                                    platform is an agentic system that simulates a 
                                    multidisciplinary research team with virtual 
                                    roles like legal advisors, inventors, critics, and 
                                    team leader.
                                </p>
                                
                                <p className="2xl:text-3xl 2xl:leading-[2.5rem]">
                                    • The platform integrates data from various 
                                    sources, using RAG for knowledge base access, 
                                    web scraping, and internal data from Excel and 
                                    documents.
                                </p>
                            </div>

                            {/* Image */}
                            <div className="w-full h-full items-center justify-center">
                                <img
                                    src={Picture8}
                                    alt="BitOoda Agentic AI Development"
                                    className="rounded-lg max-w-full h-auto"
                                />
                                <div className="2xl:text-2xl text-gray-300 italic">
                                    <div>Figure: Direct Acyclic Graph depicting interactions in the BitOoda AI Ideation 
                                        System</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InsideResearchAgentic;
