import React from 'react';

const Disclosures = () => {
  return (
    <div className="bg-gradient-to-br from-[#00237D] via-[#0b1530] to-[#00237D] min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="mt-10 px-10 text-white">
        <h1 className="text-3xl font-bold text-white mb-4">Disclosures</h1>
        <p className="text-sm text-gray-100 mb-8">Last Updated February 24, 2025</p>

        <div className="prose max-w-none">
          <h2 className="text-2xl font-semibold text-gray-200 mb-4">General Information Only</h2>
          <p className="text-gray-200 mb-6">
            Any analysis, commentary, or research materials provided on this Website are for general informational purposes only and are not intended to constitute legal, tax, investment, or other professional advice. No fiduciary or advisory relationship exists between FPX and you solely by virtue of your accessing or using this Website.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">Forward-Looking Statements (If Applicable)</h2>
          <p className="text-gray-200 mb-6">
            Certain statements made available on this Website may be considered "forward-looking statements." These statements often include words such as "anticipate," "believe," "intend," "estimate," "expect," "project," or similar expressions. Forward-looking statements are based on assumptions and expectations that may or may not occur. Actual results could differ materially due to various factors beyond our control. FPX undertakes no obligation to publicly update or revise any forward-looking statements.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">No Offer or Solicitation</h2>
          <p className="text-gray-200 mb-6">
            Nothing on this Website should be construed as a solicitation, offer, or recommendation to buy or sell any securities, financial instruments, or other assets. FPX is not a broker-dealer, investment adviser, or financial institution, and no content on this Website is intended to be, nor should be construed as, financial advice tailored to your circumstances.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">Third-Party Data and Content</h2>
          <p className="text-gray-200 mb-6">
            From time to time, FPX may reference or rely on information, reports, or analytics compiled or provided by third parties. FPX makes no guarantees as to the accuracy, completeness, or timeliness of any third-party information. You should independently verify any data or content before relying on it, and your reliance on any third-party materials is at your own risk.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">No Guarantee of Results</h2>
          <p className="text-gray-200 mb-6">
            Past performance of any product, service, or market referenced on this Website does not guarantee future results. Any predictions or projections provided through our research or commentary are inherently uncertain and subject to change.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">Conflicts of Interest (If Relevant)</h2>
          <p className="text-gray-200 mb-6">
            FPX may have existing commercial relationships or partnerships that could influence the perspective presented in our materials. Where relevant, we endeavor to identify potential conflicts of interest; however, the presence or absence of specific disclosure does not imply any endorsement or lack thereof of particular products, services, or companies.
          </p>

          <h2 className="text-2xl font-semibold text-gray-200 mb-4">Use at Your Own Risk</h2>
          <p className="text-gray-200 mb-6">
            Your use of the information, research, or other materials provided on this Website is solely at your own risk. You agree that FPX is not liable for any damages or losses arising from your reliance on the content presented. We strongly recommend consulting with qualified professionals before making significant financial or legal decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclosures;
